.product-section {
	$this: &;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-top: 100px;
	padding-bottom: 100px;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&.section-fp-inner {
		padding-top: 0;
		padding-bottom: 0; }
	&__content {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 100%; }
	&__text-part {
		margin-left: percentage(100/1440);
		position: relative;
		z-index: 1;
		width: percentage(600/1440);
		padding-top: 3%; }
	&__title {
		color: #41505b;
		@include css-lock(44, 80, 960, 2560);
		@include ligthItalic;
		letter-spacing: 4px;
		text-transform: uppercase;
		opacity: 0;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		@include r(1260) {
			@include css-lock(30, 80, 960, 2560); }
		.letter {
			@include css-lock(20, 36, 960, 2560, 'min-width'); } }
	&__text {
		color: #2f2f2f;
		@include RobotoLightItalic;
		margin-bottom: 20px;
		@include css-lock(14, 23, 960, 2560);
		opacity: 0;
		margin-bottom: 50px; }
	&__btn-wrapper {
		text-align: right;
		font-size: 16px;
		padding-right: 20px;
		opacity: 0;
		a {
			display: inline-flex;
			align-items: center;
			img {
				width: 18px;
				height: 12px;
				opacity: 0.77;
				margin-left: 5px; } } }
	&__footer {
		position: absolute;
		z-index: 1;
		left: 0;
		bottom: percentage(28/700);
		padding-left: percentage(100/1440);
		opacity: 0;
		p {
			@include css-lock(6, 20, 960, 2560);
			color: #5d5d5d;
			opacity: 0.68;
			display: inline-block;
			vertical-align: top;
			letter-spacing: 0.4px;
			@include RobotoLightItalic;
			&:not(:last-of-type) {
				margin-right: 20px; } }
		a {
			transition: color 0.3s;
			&:hover {
				color: #000;
				@include RobotoItalic; } } } }

.charity-block {
	align-items: flex-end;
	display: flex;
	margin-bottom: 10px;
	justify-content: space-between;
	opacity: 0;
	&__img {
		flex-shrink: 0;
		padding-right: 20px;
		width: 155px;
		img {
			max-width: 125px;
			height: auto; } }
	&__text {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 54px;
		color: #2f2f2f;
		@include css-lock(14, 23, 960, 2560); }
	&__logos-wrapper {
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		min-width: 120px;
		max-width: 120px;
		padding-bottom: 5px; }
	&__logo {
		display: block;
		&:last-of-type {
			height: auto;
			width: 100%; }
		&:not(:last-of-type) {
			height: 40px;
			margin-bottom: 26px; } } }

.product-gallery {
	$this: &;
	width: 100%;
	&__desc {
		color: #414141;
		@include RobotoLightItalic;
		font-size: 16px;
		line-height: 1.5;
		&--white {
			color: #FFFFFF; } }
	&__items {
		width: 100%;
		height: 100%;
		position: relative;
		margin-bottom: 30px; }
	&__item {
		&:not(:last-child) {
			margin-bottom: 10px; }
		&:nth-of-type(1) {
			#{$this}__item-overlay {
				background-color: #fff; } }
		&:nth-of-type(2) {
			#{$this}__item-overlay {
				background-color: #26ab94; } } }
	&__item-overlay {
		background-color: #ffffff;
		background-image: url('./../img/noise.png');
		height: 100%;
		position: absolute;
		top: 0;
		transform: translateY(-101%);
		left: 0;
		width: 100%; }
	&__media {
		height: 100%;
		position: absolute;
		overflow: hidden;
		width: 100%; }
	&__item {
		position: absolute;
		overflow: hidden;
		img, video {
			height: 100%;
			width: 100%;
			object-fit: cover;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
			&.is-visible {
				opacity: 1; } }
		img {
			width: 117%;
			top: 52.2%;
			transform: translateY(-50%);
			height: 116%;
			&:nth-child(2) {
				height: 114%;
				top: 51.4%; }
			&:nth-child(3) {
				height: 114%;
				top: 51.2%; }
			&:nth-child(4) {
				height: 117%;
				top: 51.6%; } }

		&:nth-child(1) {
			top: 19%;
			right: 28.6%;
			width: percentage(260/1440);
			height: percentage(430/700);
			z-index: 1;
			#{$this}__item-overlay {
				background-color: #ffffff; } }
		&:nth-child(2) {
			top: 0%;
			right: 10%;
			width: percentage(340/1440);
			height: 100%;
			#{$this}__item-overlay {
				background-color: #38a976; } } } }
