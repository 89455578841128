.voice-btn {
	background-color: transparent;
	color: #c92b1e;
	font-size: 1rem;
	padding-left: 10px;
	position: fixed;
	top: 34px;
	right: 3.8%;
	letter-spacing: 0.06rem;
	text-transform: uppercase;
	transition: opacity ease-in-out 0.3s;
	z-index: 8;
	@include italic;
	&:before {
		@include pseudo;
		background-color: #d60000;
		border-radius: 50%;
		height: 3px;
		top: 50%;
		transform: translateY(-50%);
		width: 3px; }
	&.is-active {
		color: #68b4d7; } }
