.preloader {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	z-index: 99;
	background-color: #fff;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 4rem;
	&__wave {
		padding-bottom: 28%;
		width: 30%;
		position: relative;
		canvas {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%; } }
	&__value-wrapper {
		max-width: 500px;
		width: 100%;
		height: 2px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		z-index: 1; }
	&__percentage {
		margin-top: 10px;
		text-align: right;
		color: #7d8d92; }
	&__value {
		transition: 0.3s;
		max-width: 500px;
		width: 0;
		height: 100%;
		background-color: #dedede; }
	&.is-hidden {
		opacity: 0;
		visibility: hidden;
		z-index: -100; } }
