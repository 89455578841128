.video-section {
	$this: &;
	&__inner {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		position: relative;
		z-index: 1; }
	&__content {
		position: relative;
		max-width: 340px; }
	&__wave-wrapper {
		text-align: center;
		margin-bottom: 1.25rem;
		opacity: 0;
		position: relative; }
	&__title {
		position: relative;
		overflow: hidden;
		line-height: 1;
		opacity: 0;
		img {
			display: block;
			width: 100%; } }
	&__wave-wrapper {
		text-align: center;
		margin-bottom: 1.25rem;
		opacity: 0;
		position: relative; }
	&__head-line {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative; }
	&__desc {
		@include RobotoLightItalic;
		font-size: 17px;
		line-height: 1.5;
		text-align: center;
		letter-spacing: 0.7px;
		margin-top: 1.125rem;
		flex-basis: 100%;
		opacity: 0;
		span {
			display: inline; } }
	&__btn {
		position: relative;
		margin: auto;
		margin-left: -20px;
		width: 51px;
		min-width: 51px;
		height: 51px; }
	&__video-bg {
		@include coverdiv;
		z-index: -2;
		&:after {
			@include coverdiv;
			background-color: rgba(0, 0, 0, 0.31);
			content: ''; } }
	&__video {
		visibility: hidden;
		opacity: 0;
		@include coverdiv;
		object-fit: cover;
		&.is-active {
			opacity: 1;
			visibility: visible; } }
	&__poster {
		@include coverdiv;
		z-index: -1;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		video {
			height: 100%;
			width: 100%;
			object-fit: cover; } }
	&__action-wrapper {
		cursor: pointer; }
	&__mute {
		color: #ffffff;
		display: inline-block;
		font-size: 16px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		opacity: 0;
		visibility: hidden;
		svg {
			max-width: 100px; } }
	&__logo {
		margin-bottom: 1.25rem;
		opacity: 0;
		img {
			display: block;
			width: 100%; } }
	&__video-timeline {
		bottom: 73px;
		height: 1px;
		transform: translate(0, 0);
		position: absolute;
		left: 0;
		width: 100%;
		padding-left: 105px;
		padding-right: 160px;
		transition: opacity 0.3s;
		&.is-hidden {
			opacity: 0; }
		&:before {
			content: "";
			position: absolute;
			width: 3px;
			height: 3px;
			background-color: #ec0606;
			border-radius: 50%;
			top: 50%;
			transform: translateY(-50%);
			left: 94px; } }
	&__progress {
		background-color: #fff;
		height: 100%;
		top: 0;
		transform-origin: top left;
		left: 0;
		width: 100%; } }


.video-btn {
	background: transparent;
	@include coverdiv;
	z-index: 1;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	font-size: 0;
	$this: &;
	&__full-page-overlay {
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		width: 100%; }
	&--pause {
		opacity: 0;
		z-index: -1;
		transition: 0.5s;
		&:before, &:after {
			content: "";
			position: absolute;
			width: 3px;
			height: 24px;
			top: 50%;
			background: #fff; }
		&:before {
			left: calc(50% - 3px);
			transform: translate(-50%, -50%); }
		&:after {
			right: calc(50% - 3px);
			transform: translate(50%, -50%); }
		&.is-visible {
			opacity: 1;
			z-index: 1;
			& > #{$this}__full-page-overlay {
				display: block; } } }
	&--play {
		position: absolute;
		left: 0;
		opacity: 0;
		svg {
			width: 20px;
			height: 27px;
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-32%, -50%); }
		.play-form {
			transition: opacity, 0.3s; } }
	&__icon {
		height: 100%;
		position: absolute;
		top: -4px;
		left: 0;
		width: 100%;
		&:before {
			content: "";
			position: absolute;
			background: #fff;
			width: 100%;
			height: 100%;
			left: 50%;
			top: 50%;
			margin-left: -26px;
			margin-top: -26px;
			transform: scale(0);
			transition: transform, 0.4s;
			transition-delay: 0.15s;
			border-radius: 50%; }
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			-webkit-clip-path: polygon(40% 33%,39% 68%,69% 49%);
			clip-path: polygon(40% 33%,39% 68%,69% 49%);
			background: #4f9a9f;
			transition: opacity, 0.3s;
			opacity: 0; } }
	&__icon:hover,
	.video-section__action-wrapper:hover &__icon {
		&:before {
			transform: scale(1); }
		&:after {
			opacity: 1; }
		.play-form {
			opacity: 0; } } }

.pause-menu {
	transform: translateX(100%);
	@include coverdiv;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	&__items {
		height: 100%;
		display: flex;
		padding: 0 15% 0 100px;
		width: 100%;
		justify-content: space-between; }
	&__item {
		align-items: center;
		height: 100%;
		display: flex;
		justify-content: center;
		&:not(:last-child) {
			margin-right: 20px; } }
	&__preview {
		opacity: 0;
		visibility: hidden;
		transition: 0.5s;
		z-index: -1;
		@include coverdiv;
		video {
			height: 100%;
			width: 100%;
			object-fit: cover; }
		&.is-visible {
			opacity: 1;
			visibility: visible; } } }

.p-item {
	align-items: stretch;
	background: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 60%;
	&:hover {
		.p-item {
			&__desc {
				opacity: 1; }
			&__waves {
				opacity: 1; } }
		.video-btn__icon {
			&:before {
				transform: scale(1); }
			&:after {
				opacity: 1; } } }
	&__content {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem; }
	&__waves {
		height: 22px;
		margin-bottom: 1.5rem;
		opacity: 0;
		transition: opacity ease-in-out 0.3s; }
	&__icon {
		margin-right: 5px;
		width: 52px;
		height: 52px;
		position: relative;
		.video-btn__icon {
			display: flex;
			align-items: center;
			justify-content: center; }
		svg {
			display: block;
			width: 20px;
			height: 27px; } }
	&__title {
		color: #fff;
		opacity: 1;
		line-height: 1;
		letter-spacing: 4.47px;
		@include ligthItalic;
		@include css-lock(44, 64, 960, 2560);
		text-transform: uppercase;
		white-space: nowrap; }
	&__desc {
		color: #fff;
		font-size: 1rem;
		opacity: 0;
		transition: opacity ease-in-out 0.3s;
		min-height: 2.2em;
		@include RobotoLightItalic; }
	&.is-active {
		.pause-block {
			display: block;
			opacity: 1; }
		.play-svg {
			display: none; } } }
