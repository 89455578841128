.symphony-gallery {
	$this: &;
	padding-bottom: 53.8%;
	position: relative;
	width: 95%;
	&__item {
		cursor: pointer;
		position: absolute;
		overflow: hidden;
		&:hover {
			#{$this}__image {
				transform: scale(1.2); } }
		&:nth-child(1) {
			top: 8.1%;
			padding-bottom: 38.1%;
			width: 24.3%; }
		&:nth-child(2) {
			left: 26.1%;
			padding-bottom: 23.1%;
			width: 40%; }
		&:nth-child(3) {
			top: 8.1%;
			right: 0;
			padding-bottom: 20%;
			width: 32.3%; }
		&:nth-child(4) {
			top: 45%;
			left: 21.5%;
			padding-bottom: 29.7%;
			width: 24.9%; }
		&:nth-child(5) {
			top: 45%;
			left: 47.7%;
			padding-bottom: 29.7%;
			width: 18.3%; }
		&:nth-child(6) {
			top: 47.7%;
			right: 0;
			padding-bottom: 17.4%;
			width: 32.3%; } }
	&__image {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		transform: scale(1);
		transition: transform ease 1s;
		width: 100%; } }
