.home-copy {
    color: #fff;
    position: absolute;
    bottom: 60px;
    left: 100px;
    font-family: Roboto-Italic, Arial, sans-serif;
    font-weight: 400;
    font-size: 22px;
    font-size: calc(10px + (22 - 10) * ((100vw - 960px) / (2560 - 960)));
    display: flex;
    align-items: center;
    p {
        margin-right: 30px;
    }
    z-index: 999;
}

.instruments-copy {
    color: #fff;
    position: absolute;
    bottom: 25px;
    left: 100px;
    font-family: Roboto-Italic, Arial, sans-serif;
    font-weight: 400;
    color: #737373;
    line-height: 1.6;
    letter-spacing: 0.5px;
    font-size: 15px;
    font-size: calc(11px + (15 - 11) * ((100vw - 960px) / (2560 - 960)));
    display: flex;
    align-items: center;
    p {
        margin-right: 30px;
    }
    z-index: 999;
}

.artist-copy {
    color: #fff;
    position: absolute;
    bottom: 30px;
    left: 100px;
    font-family: Roboto-Italic, Arial, sans-serif;
    font-weight: 400;
    font-size: 22px;
    font-size: calc(10px + (22 - 10) * ((100vw - 960px) / (2560 - 960)));
    display: flex;
    align-items: center;
    p {
        margin-right: 30px;
    }
    z-index: 999;
}
