.icon-angle-top {
  width: 1.92em;
  height: 1em;
  fill: red;
}
.icon-apple-music {
  width: 1em;
  height: 1em;
  fill: #93bad0;
}
.icon-apple {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-r-semicircle {
  width: 0.46em;
  height: 1em;
  fill: none;
}
.icon-arrow-r {
  width: 1.36em;
  height: 1em;
  fill: none;
}
.icon-artist1 {
  width: 0.96em;
  height: 1em;
  fill: #535353;
}
.icon-artist2 {
  width: 0.99em;
  height: 1em;
  fill: #535353;
}
.icon-artist3 {
  width: 0.99em;
  height: 1em;
  fill: #535353;
}
.icon-artist4 {
  width: 0.96em;
  height: 1em;
  fill: #535353;
}
.icon-bubble {
  width: 1em;
  height: 1em;
  fill: #b6d7e5;
}
.icon-download {
  width: 1.19em;
  height: 1em;
  fill: none;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}
.icon-google-music {
  width: 1em;
  height: 1em;
  fill: #93bad0;
}
.icon-instrument1 {
  width: 0.94em;
  height: 1em;
  fill: #525252;
}
.icon-instrument2 {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-instrument3 {
  width: 0.97em;
  height: 1em;
  fill: #525252;
}
.icon-instrument4 {
  width: 0.88em;
  height: 1em;
  fill: #525252;
}
.icon-instrument5 {
  width: 0.88em;
  height: 1em;
  fill: #525252;
}
.icon-instrument6 {
  width: 1.08em;
  height: 1em;
  fill: none;
}
.icon-pause {
  width: 0.64em;
  height: 1em;
  fill: #26AB94;
}
.icon-pause_bubble {
  width: 0.56em;
  height: 1em;
  fill: #68B4D7;
}
.icon-play-player {
  width: 0.84em;
  height: 1em;
  fill: #fff;
}
.icon-play_bubble {
  width: 0.71em;
  height: 1em;
  fill: #68B4D7;
}
.icon-sound {
  width: 1.24em;
  height: 1em;
  fill: none;
}
.icon-spotify {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-unmute {
  width: 1.15em;
  height: 1em;
  fill: #03CFA5;
}
