.step {
	$this: &;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 160px;
	z-index: 7;
	overflow: hidden;
	font-size: 14px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	&:not(.is-olways-visible) {
		transition: opacity 0.3s;
		opacity: 0;
		visibility: hidden;
		#{$this}__overlay {
			transform: translateX(100%); }
		&.is-active {
			visibility: visible;
			opacity: 1;
			#{$this}__overlay {
				transform: translateX(0); } } }
	&__btns {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 40px 18px 64px 15px;
		@include coverdiv;
		cursor: pointer;
		&.is-hovered {
			#{$this}__btn {
				&--light {
					color: #404040;
					#{$this}__btn-line {
						background-color: #404040; } } } } }
	&__btn-line {
		display: block;
		flex-grow: 1;
		margin-right: 13px;
		position: relative;
		height: 3px;
		border-radius: 15%;
		overflow: hidden;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			border-radius: 50%;
			background: #e00926;
			width: 3px;
			height: 3px; }
		&:after {
			content: "";
			position: absolute;
			width: calc(100% - 7px);
			top: 50%;
			height: 1px;
			margin-top: -1px;
			opacity: 0.34;
			background-color: #444;
			transition: transform 0.3s; }
		&.is-animated {
			&:before {
				animation: nextStep 1.3s infinite;
				animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
			&:after {
				display: none; } } }
	&__btn {
		display: flex;
		align-items: center;
		width: 100%;
		color: #535353;
		@include RobotoLightItalic;
		position: relative;
		letter-spacing: 1px;
		& + & {
			margin-top: 10px; }
		span[data-hover-transform] {
			position: relative;
			transition: color 0.2s;
			&:before {
				content: attr(data-hover-transform);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
				color: #d90b30;
				transition: transform 0.3s, opacity 0.3s;
				@include RobotoItalic;
				opacity: 0; } }
		&--light {
			color: #e7f1f5;
			#{$this}__btn-line {
				&:after {
					background-color: #dfebef; } } }
		&--next {
			span[data-hover-transform] {
				&:before {
					transform: translate(-2px, 0px); } }
			#{$this}__btn-line {
				&:after {
					right: 0; } }
			&:hover {
				span[data-hover-transform] {
					color: transparent;
					&:before {
						transform: translate(0px, 0px);
						opacity: 1; } }
				#{$this}__btn-line {
					&:before {
						animation: nextStep 1.3s infinite;
						animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
					&:after {
						transform: translateX(100%); } } } }
		&--back {
			span[data-hover-transform] {
				&:before {
					transform: translate(2px, 0px); } }
			#{$this}__btn-line {
				order: 2;
				margin-right: 0;
				margin-left: 13px;
				&:before {
					right: 0;
					left: auto;
					animation: none; }
				&:after {
					left: 0; } }
			&:hover {
				span[data-hover-transform] {
					color: transparent;
					&:before {
						transform: translate(0px, 0px);
						opacity: 1; } }
				#{$this}__btn-line {
					&:before {
						animation: prevStep 1.3s infinite;
						animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
					&:after {
						transform: translateX(-100%); } } } } }
	&__overlays {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		z-index: -2; }
	&__overlay {
		transition: transform ease-out 0.4s;
		@include coverdiv;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		&[data-step="back"] {
			opacity: 0; }
		&--double-width {
			width: 200%; } }
	&.is-olways-visible {
		width: 10%;
		.step__btns {
			padding: 40px 20px 66px 15px; } } }

.step {
	&:hover {
		.step__overlay[data-step="next"] {
			transform: translateX(-50%)!important; } } }

.step-fixed-btn {
	letter-spacing: -0.05px;
	position: fixed;
	bottom: 63px;
	right: 20px;
	max-width: 127px;
	display: inline-flex;
	z-index: 8;
	font-size: 14px;
	opacity: 0;
	transition: opacity 0.3s;
	&.is-visible {
		opacity: 1; }
	&:hover {
		&:hover + .step {
			.step__overlay[data-step="next"] {
				transform: translateX(-50%)!important; } } } }

.step-back-overlay {
	z-index: -1;
	@include coverdiv;
	width: 150%;
	background-color: #FFFFFF;
	opacity: 1;
	transform: translateX(-100%); }

