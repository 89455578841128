@include font(Oswald-Light, Oswald-Light);
@include font(Oswald-LightItalic, Oswald-LightItalic);
@include font(Oswald-Regular, Oswald-Regular);
@include font(Oswald-RegularItalic, Oswald-RegularItalic);
@include font(Oswald-ExtraLight, Oswald-ExtraLight);

@include font(Roboto-LightItalic, Roboto-LightItalic);
@include font(Roboto-ThinItalic, Roboto-LightItalic);
@include font(Roboto-Italic, Roboto-LightItalic);
@include font(Roboto-Light, Roboto-Light);

@mixin RobotoItalic {
	font-family: Roboto-Italic, Arial, sans-serif;
	font-weight: 400; }

@mixin RobotoLight {
	font-family: Roboto-Light, Arial, sans-serif;
	font-weight: 300;
	font-style: normal; }

@mixin RobotoLightItalic {
	font-family: Roboto-LightItalic, Arial, sans-serif;
	font-weight: 300; }

@mixin RobotoThinItalic {
	font-family: Roboto-ThinItalic, Arial, sans-serif;
	font-weight: 100; }

@mixin regular {
	font-family: Oswald-Regular, Arial, sans-serif;
	font-weight: 400;
	font-style: normal; }

@mixin italic {
	font-family: Oswald-RegularItalic, Arial, sans-serif;
	font-weight: 400; }

@mixin ligth {
	font-family: Oswald-Light, Arial, sans-serif;
	font-weight: 300;
	font-style: normal; }

@mixin ligthItalic {
	font-family: Oswald-LightItalic, Arial, sans-serif;
	font-weight: 300; }

@mixin extraLight {
	font-family: Oswald-ExtraLight, Arial, sans-serif;
	font-weight: 200; }

body {
	@include RobotoItalic;
	background: #FFF;
	line-height: 1.4; }

#container {
	canvas {
		height: 100%;
		width: 100%; } }

.barba-container {
	height: 100vh;
	position: relative;
	width: 100vw; }

.waves {
	height: 48px;
	position: absolute;
	top: 0;
	left: 0; }

.app {
	height: 100vh;
	overflow: hidden;
	position: relative; }

.section {
	@include coverdiv;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center; }

.section-fp {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	&:not(.is-active) {
		display: none; }
	&.is-active {
		opacity: 1;
		visibility: visible;
		.artist-section-overlay {
			animation: artistsBg 10s;
			animation-fill-mode: forwards; } } }

@keyframes artistsBg {
	0% {
		transform: translate(-45%, -45%) scale(1.2); }
	20% {
		transform: translate(-50%, -50%) scale(1.2); }
	100% {
		transform: translate(-50%, -50%) scale(1); } }

.section-fp-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  &.is-active {
    opacity: 1;
    visibility: visible; } }

a {
	color: inherit;
	text-decoration: none; }

.page-overlay {
	background-color: #FFFFFF;
	background-image: url(./../img/noise.png);
	background-repeat: repeat;
	transform: scaleX(0);
	z-index: 12;
	@include coverdiv; }

.up {
	cursor: pointer;
	align-items: center;
	display: flex;
	flex-direction: column;
	opacity: 0;
	position: absolute;
	font-size: 13px;
	left: 45%;
	margin-left: -5px;
	bottom: 5.5rem;
	transition: opacity ease-in-out 0.3s;
	transition-delay: 0s;
	text-align: center;
	z-index: -1;
	$this: &;
	&--light {
		#{$this} {
			&__text {
				color: #fff; }
			&__arrow {
				svg {
					stroke: #fff; } } } }
	&:hover {
		.up__text {
			color: #f54443; } }
	&__icon {
		position: absolute;
		top: -200%;
		margin-top: -14px;
		left: 0;
		width: 30px; }
	&__arrow,
	&__semicircle {
		display: block;
		position: relative;
		transition: transform 0.5s, opacity 0.5s;
		overflow: hidden;
		width: 100%;
		svg {
			transform: rotate(-90deg);
			width: 100%; } }
	&__semicircle {
		display: block;
		position: relative;
		top: 17px;
		svg {
			height: 30px;
			fill: none;
			stroke: #d14140; } }
	&__arrow {
		svg {
			stroke: #000;
			width: 13px; } }
	&__text {
		transition: color 0.3s;
		color: #95aac0;
		text-transform: lowercase;
		@include RobotoLightItalic; }
	&:hover {
		#{$this}__semicircle {
			opacity: 0;
			transform: scale(0); }
		#{$this}__arrow {
			animation: knowAnimTop 2s infinite;
			animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
			animation-delay: 0.3s; } } }

@keyframes knowAnimTop {
	0% {}
	35% {
		transform: translateY(-20px);
		opacity: 0; }
	50% {
		opacity: 0;
		transform: translateY(0px); }
	75% {}
	100% {
		opacity: 1; } }

.arrow-link {
	$this: &;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 15px;
	margin-right: 5px;
	&__wrap {
		margin-left: 15px;
		font-size: 0;
		display: flex;
		align-items: center; }
	&__arrow {
		display: block;
		svg {
			stroke: #000;
			width: 12px;
			height: 10px; } }
	&__semicircle {
		transform-origin: 0% 50%;
		transition: transform 0.5s, opacity 0.5s;
		svg {
			stroke: #d14140;
			width: 11px;
			height: 25px; } }
	&:hover {
		#{$this}__semicircle {
			opacity: 0;
			transform: scale(0); }
		#{$this}__arrow {
			animation: knowAnim 2s infinite;
			animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
			animation-delay: 0.3s; } } }

@keyframes knowAnim {
	0% {}
	35% {
		transform: translateX(20px);
		opacity: 0; }
	50% {
		opacity: 0;
		transform: translateX(0px); }
	75% {}
	100% {
		opacity: 1; } }

.is-last-section {
	.scroll-btn {
		opacity: 0;
		visibility: hidden;
		display: none; }
	.up {
		z-index: 1;
		opacity: 1;
		transition-delay: 0.3s; } }

.muted {
  position: fixed;
  display: flex;
  font-size: 13px;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 49px;
  top: -100%;
  left: -100%;
  white-space: nowrap;
  z-index: 1000;
  .icon {
    font-size: 18px;
    margin-right: 9px; } }


@keyframes nextStep {
	0% {
		left: -20%;
		transform: scaleX(1); }
	5% {
		transform: scaleX(4); }
	40% {
		transform: scaleX(1); }
	50%, 100% {
		left: 100%; } }


@keyframes prevStep {
	0% {
		right: -20%;
		transform: scaleX(1); }
	5% {
		transform: scaleX(4); }
	40% {
		transform: scaleX(1); }
	50%, 100% {
		right: 100%; } }

.scroll-btn {
	animation: scroll 1.2s infinite;
	animation-timing-function: cubic-bezier(.42,.35,1,.65);
	animation-delay: 1s;
	background-color: #C92B1E;
	bottom: 3rem;
	border-radius: 50%;
	height: 24px;
	position: fixed;
	left: 50%;
	pointer-events: none;
	margin-left: -5%;
	width: 24px;
	z-index: 8; }

@keyframes scroll {
	0% {
		transform: scale(0.15) translateY(0); }
	20% {
		transform: scale(1); }
	30% {
		transform: scale(1); }
	50% {
		transform: scale(0.15); }
	100% {
		transform: translateY(120px) scale(0.15); } }
