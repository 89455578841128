.btn {
	display: inline-block;
	font-size: 14px;
	padding: 10px 23px 10px;
	border-radius: 21px;
	background-color: #ffffff;
	white-space: nowrap;
	color: rgba(69, 69, 69, 0.73);
	letter-spacing: 0.5px;
	opacity: 0.73;
	@include RobotoLightItalic;
	&__inner {
		display: flex;
		align-items: center;
		justify-content: center; }
	&__icon {
		margin-right: 10px;
		width: 14px;
		height: 15px;
		opacity: 0.77;
		svg {
			display: block;
			max-width: 100%;
			max-height: 100%; } } }

.mute-btn {
	background-color: transparent;
	.icon {
		font-size: 24px;
		stroke: #535353; } }

.video-link {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s;
	z-index: 11;
	position: absolute;
	top: 26px;
	right: 14%;
	display: flex;
	color: #68b4d7;
	justify-content: stretch;
	&__icon {
		display: block;
		max-height: 100%;
		min-height: 100%;
		svg {
			position: absolute;
			top: 0;
			left: 100%;
			height: 100%; } }
	&__text {
		@include RobotoThinItalic;
		font-size: 15px;
		letter-spacing: 1.79px;
		text-align: right;
		margin-right: 10px;
		@include css-lock(10, 15, 960, 2560); }
	&--light {
		color: #fff; }
	&.is-visible {
		opacity: 1;
		visibility: visible; } }
