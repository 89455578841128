/*! nouislider - 12.1.0 - 10/25/2018 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform .3s;
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #EBEBEB,0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB;
}

.noUi-handle:after, .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.instruments-list__item:before, .instruments-gallery__play-btn:after, .artists-list__link:before, .voice-btn:before {
  position: relative;
}

.instruments-list__item:before, .artists-list__link:before, .voice-btn:before, .instruments-gallery__play-btn:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

.icon-angle-top {
  width: 1.92em;
  height: 1em;
  fill: red;
}

.icon-apple-music {
  width: 1em;
  height: 1em;
  fill: #93bad0;
}

.icon-apple {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-arrow-r-semicircle {
  width: 0.46em;
  height: 1em;
  fill: none;
}

.icon-arrow-r {
  width: 1.36em;
  height: 1em;
  fill: none;
}

.icon-artist1 {
  width: 0.96em;
  height: 1em;
  fill: #535353;
}

.icon-artist2 {
  width: 0.99em;
  height: 1em;
  fill: #535353;
}

.icon-artist3 {
  width: 0.99em;
  height: 1em;
  fill: #535353;
}

.icon-artist4 {
  width: 0.96em;
  height: 1em;
  fill: #535353;
}

.icon-bubble {
  width: 1em;
  height: 1em;
  fill: #b6d7e5;
}

.icon-download {
  width: 1.19em;
  height: 1em;
  fill: none;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}

.icon-google-music {
  width: 1em;
  height: 1em;
  fill: #93bad0;
}

.icon-instrument1 {
  width: 0.94em;
  height: 1em;
  fill: #525252;
}

.icon-instrument2 {
  width: 1em;
  height: 1em;
  fill: none;
}

.icon-instrument3 {
  width: 0.97em;
  height: 1em;
  fill: #525252;
}

.icon-instrument4 {
  width: 0.88em;
  height: 1em;
  fill: #525252;
}

.icon-instrument5 {
  width: 0.88em;
  height: 1em;
  fill: #525252;
}

.icon-instrument6 {
  width: 1.08em;
  height: 1em;
  fill: none;
}

.icon-pause {
  width: 0.64em;
  height: 1em;
  fill: #26AB94;
}

.icon-pause_bubble {
  width: 0.56em;
  height: 1em;
  fill: #68B4D7;
}

.icon-play-player {
  width: 0.84em;
  height: 1em;
  fill: #fff;
}

.icon-play_bubble {
  width: 0.71em;
  height: 1em;
  fill: #68B4D7;
}

.icon-sound {
  width: 1.24em;
  height: 1em;
  fill: none;
}

.icon-spotify {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-unmute {
  width: 1.15em;
  height: 1em;
  fill: #03CFA5;
}

@font-face {
  font-family: Oswald-Light;
  src: url("../fonts/Oswald-Light.woff2") format("woff2"), url("../fonts/Oswald-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Oswald-LightItalic;
  src: url("../fonts/Oswald-LightItalic.woff2") format("woff2"), url("../fonts/Oswald-LightItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Oswald-Regular;
  src: url("../fonts/Oswald-Regular.woff2") format("woff2"), url("../fonts/Oswald-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Oswald-RegularItalic;
  src: url("../fonts/Oswald-RegularItalic.woff2") format("woff2"), url("../fonts/Oswald-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Oswald-ExtraLight;
  src: url("../fonts/Oswald-ExtraLight.woff2") format("woff2"), url("../fonts/Oswald-ExtraLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-LightItalic;
  src: url("../fonts/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: url("../fonts/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-Italic;
  src: url("../fonts/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-Light;
  src: url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
  background: #FFF;
  line-height: 1.4;
}

#container canvas {
  height: 100%;
  width: 100%;
}

.barba-container {
  height: 100vh;
  position: relative;
  width: 100vw;
}

.waves {
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.app {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section-fp {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

.section-fp:not(.is-active) {
  display: none;
}

.section-fp.is-active {
  opacity: 1;
  visibility: visible;
}

.section-fp.is-active .artist-section-overlay {
  animation: artistsBg 10s;
  animation-fill-mode: forwards;
}

@keyframes artistsBg {
  0% {
    transform: translate(-45%, -45%) scale(1.2);
  }
  20% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.section-fp-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.section-fp-inner.is-active {
  opacity: 1;
  visibility: visible;
}

a {
  color: inherit;
  text-decoration: none;
}

.page-overlay {
  background-color: #FFFFFF;
  background-image: url(./../img/noise.png);
  background-repeat: repeat;
  transform: scaleX(0);
  z-index: 12;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.up {
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  font-size: 13px;
  left: 45%;
  margin-left: -5px;
  bottom: 5.5rem;
  transition: opacity ease-in-out 0.3s;
  transition-delay: 0s;
  text-align: center;
  z-index: -1;
}

.up--light .up__text {
  color: #fff;
}

.up--light .up__arrow svg {
  stroke: #fff;
}

.up:hover .up__text {
  color: #f54443;
}

.up__icon {
  position: absolute;
  top: -200%;
  margin-top: -14px;
  left: 0;
  width: 30px;
}

.up__arrow, .up__semicircle {
  display: block;
  position: relative;
  transition: transform 0.5s, opacity 0.5s;
  overflow: hidden;
  width: 100%;
}

.up__arrow svg, .up__semicircle svg {
  transform: rotate(-90deg);
  width: 100%;
}

.up__semicircle {
  display: block;
  position: relative;
  top: 17px;
}

.up__semicircle svg {
  height: 30px;
  fill: none;
  stroke: #d14140;
}

.up__arrow svg {
  stroke: #000;
  width: 13px;
}

.up__text {
  transition: color 0.3s;
  color: #95aac0;
  text-transform: lowercase;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
}

.up:hover .up__semicircle {
  opacity: 0;
  transform: scale(0);
}

.up:hover .up__arrow {
  animation: knowAnimTop 2s infinite;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-delay: 0.3s;
}

@keyframes knowAnimTop {
  0% {
  }
  35% {
    transform: translateY(-20px);
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateY(0px);
  }
  75% {
  }
  100% {
    opacity: 1;
  }
}

.arrow-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 5px;
}

.arrow-link__wrap {
  margin-left: 15px;
  font-size: 0;
  display: flex;
  align-items: center;
}

.arrow-link__arrow {
  display: block;
}

.arrow-link__arrow svg {
  stroke: #000;
  width: 12px;
  height: 10px;
}

.arrow-link__semicircle {
  transform-origin: 0% 50%;
  transition: transform 0.5s, opacity 0.5s;
}

.arrow-link__semicircle svg {
  stroke: #d14140;
  width: 11px;
  height: 25px;
}

.arrow-link:hover .arrow-link__semicircle {
  opacity: 0;
  transform: scale(0);
}

.arrow-link:hover .arrow-link__arrow {
  animation: knowAnim 2s infinite;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-delay: 0.3s;
}

@keyframes knowAnim {
  0% {
  }
  35% {
    transform: translateX(20px);
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateX(0px);
  }
  75% {
  }
  100% {
    opacity: 1;
  }
}

.is-last-section .scroll-btn {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.is-last-section .up {
  z-index: 1;
  opacity: 1;
  transition-delay: 0.3s;
}

.muted {
  position: fixed;
  display: flex;
  font-size: 13px;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 49px;
  top: -100%;
  left: -100%;
  white-space: nowrap;
  z-index: 1000;
}

.muted .icon {
  font-size: 18px;
  margin-right: 9px;
}

@keyframes nextStep {
  0% {
    left: -20%;
    transform: scaleX(1);
  }
  5% {
    transform: scaleX(4);
  }
  40% {
    transform: scaleX(1);
  }
  50%, 100% {
    left: 100%;
  }
}

@keyframes prevStep {
  0% {
    right: -20%;
    transform: scaleX(1);
  }
  5% {
    transform: scaleX(4);
  }
  40% {
    transform: scaleX(1);
  }
  50%, 100% {
    right: 100%;
  }
}

.scroll-btn {
  animation: scroll 1.2s infinite;
  animation-timing-function: cubic-bezier(0.42, 0.35, 1, 0.65);
  animation-delay: 1s;
  background-color: #C92B1E;
  bottom: 3rem;
  border-radius: 50%;
  height: 24px;
  position: fixed;
  left: 50%;
  pointer-events: none;
  margin-left: -5%;
  width: 24px;
  z-index: 8;
}

@keyframes scroll {
  0% {
    transform: scale(0.15) translateY(0);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.15);
  }
  100% {
    transform: translateY(120px) scale(0.15);
  }
}

.block {
  display: flex;
}

.home-copy {
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 100px;
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  font-size: calc(10px + (22 - 10) * ((100vw - 960px) / (2560 - 960)));
  display: flex;
  align-items: center;
  z-index: 999;
}

.home-copy p {
  margin-right: 30px;
}

.instruments-copy {
  color: #fff;
  position: absolute;
  bottom: 25px;
  left: 100px;
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
  color: #737373;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-size: 15px;
  font-size: calc(11px + (15 - 11) * ((100vw - 960px) / (2560 - 960)));
  display: flex;
  align-items: center;
  z-index: 999;
}

.instruments-copy p {
  margin-right: 30px;
}

.artist-copy {
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: 100px;
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  font-size: calc(10px + (22 - 10) * ((100vw - 960px) / (2560 - 960)));
  display: flex;
  align-items: center;
  z-index: 999;
}

.artist-copy p {
  margin-right: 30px;
}

.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  z-index: 10;
  padding: 30px 20px;
}

.header__inner {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.header__logo {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%);
}

.logo {
  background: #fff;
  padding: 8px 0px 5px 1px;
  display: block;
  width: 129px;
  height: 33px;
}

.logo img {
  height: 100%;
  width: 100%;
}

.nav {
  color: #455255;
  display: flex;
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  font-size: calc(10px + (22 - 10) * ((100vw - 960px) / (2560 - 960)));
  letter-spacing: 1.07px;
}

@media (max-width: 960px) {
  .nav {
    font-size: 10px;
  }
}

@media (min-width: 2560px) {
  .nav {
    font-size: 22px;
  }
}

.nav.is-light .nav__link {
  color: #fff;
}

.nav.is-light .nav__link.is-active {
  color: rgba(255, 255, 255, 0.26);
}

.nav__item:not(:last-child) {
  padding-right: 30px;
  position: relative;
  margin-right: 31px;
}

.nav__item:not(:last-child):after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 4px;
  width: 4px;
  background-color: #ff0000;
  border-radius: 50%;
}

.nav__link.is-active {
  color: rgba(91, 99, 111, 0.26);
}

.video-section__inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.video-section__content {
  position: relative;
  max-width: 340px;
}

.video-section__wave-wrapper {
  text-align: center;
  margin-bottom: 1.25rem;
  opacity: 0;
  position: relative;
}

.video-section__title {
  position: relative;
  overflow: hidden;
  line-height: 1;
  opacity: 0;
}

.video-section__title img {
  display: block;
  width: 100%;
}

.video-section__wave-wrapper {
  text-align: center;
  margin-bottom: 1.25rem;
  opacity: 0;
  position: relative;
}

.video-section__head-line {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.video-section__desc {
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
  font-size: 17px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.7px;
  margin-top: 1.125rem;
  flex-basis: 100%;
  opacity: 0;
}

.video-section__desc span {
  display: inline;
}

.video-section__btn {
  position: relative;
  margin: auto;
  margin-left: -20px;
  width: 51px;
  min-width: 51px;
  height: 51px;
}

.video-section__video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.video-section__video-bg:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  content: '';
}

.video-section__video {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-section__video.is-active {
  opacity: 1;
  visibility: visible;
}

.video-section__poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.video-section__poster video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video-section__action-wrapper {
  cursor: pointer;
}

.video-section__mute {
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

.video-section__mute svg {
  max-width: 100px;
}

.video-section__logo {
  margin-bottom: 1.25rem;
  opacity: 0;
}

.video-section__logo img {
  display: block;
  width: 100%;
}

.video-section__video-timeline {
  bottom: 73px;
  height: 1px;
  transform: translate(0, 0);
  position: absolute;
  left: 0;
  width: 100%;
  padding-left: 105px;
  padding-right: 160px;
  transition: opacity 0.3s;
}

.video-section__video-timeline.is-hidden {
  opacity: 0;
}

.video-section__video-timeline:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #ec0606;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: 94px;
}

.video-section__progress {
  background-color: #fff;
  height: 100%;
  top: 0;
  transform-origin: top left;
  left: 0;
  width: 100%;
}

.video-btn {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 0;
}

.video-btn__full-page-overlay {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  width: 100%;
}

.video-btn--pause {
  opacity: 0;
  z-index: -1;
  transition: 0.5s;
}

.video-btn--pause:before, .video-btn--pause:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 24px;
  top: 50%;
  background: #fff;
}

.video-btn--pause:before {
  left: calc(50% - 3px);
  transform: translate(-50%, -50%);
}

.video-btn--pause:after {
  right: calc(50% - 3px);
  transform: translate(50%, -50%);
}

.video-btn--pause.is-visible {
  opacity: 1;
  z-index: 1;
}

.video-btn--pause.is-visible > .video-btn__full-page-overlay {
  display: block;
}

.video-btn--play {
  position: absolute;
  left: 0;
  opacity: 0;
}

.video-btn--play svg {
  width: 20px;
  height: 27px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-32%, -50%);
}

.video-btn--play .play-form {
  transition: opacity, 0.3s;
}

.video-btn__icon {
  height: 100%;
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
}

.video-btn__icon:before {
  content: "";
  position: absolute;
  background: #fff;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  margin-left: -26px;
  margin-top: -26px;
  transform: scale(0);
  transition: transform, 0.4s;
  transition-delay: 0.15s;
  border-radius: 50%;
}

.video-btn__icon:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(40% 33%, 39% 68%, 69% 49%);
  clip-path: polygon(40% 33%, 39% 68%, 69% 49%);
  background: #4f9a9f;
  transition: opacity, 0.3s;
  opacity: 0;
}

.video-btn__icon:hover:before,
.video-section__action-wrapper:hover .video-btn__icon:before {
  transform: scale(1);
}

.video-btn__icon:hover:after,
.video-section__action-wrapper:hover .video-btn__icon:after {
  opacity: 1;
}

.video-btn__icon:hover .play-form,
.video-section__action-wrapper:hover .video-btn__icon .play-form {
  opacity: 0;
}

.pause-menu {
  transform: translateX(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pause-menu__items {
  height: 100%;
  display: flex;
  padding: 0 15% 0 100px;
  width: 100%;
  justify-content: space-between;
}

.pause-menu__item {
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;
}

.pause-menu__item:not(:last-child) {
  margin-right: 20px;
}

.pause-menu__preview {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pause-menu__preview video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.pause-menu__preview.is-visible {
  opacity: 1;
  visibility: visible;
}

.p-item {
  align-items: stretch;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60%;
}

.p-item:hover .p-item__desc {
  opacity: 1;
}

.p-item:hover .p-item__waves {
  opacity: 1;
}

.p-item:hover .video-btn__icon:before {
  transform: scale(1);
}

.p-item:hover .video-btn__icon:after {
  opacity: 1;
}

.p-item__content {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.p-item__waves {
  height: 22px;
  margin-bottom: 1.5rem;
  opacity: 0;
  transition: opacity ease-in-out 0.3s;
}

.p-item__icon {
  margin-right: 5px;
  width: 52px;
  height: 52px;
  position: relative;
}

.p-item__icon .video-btn__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-item__icon svg {
  display: block;
  width: 20px;
  height: 27px;
}

.p-item__title {
  color: #fff;
  opacity: 1;
  line-height: 1;
  letter-spacing: 4.47px;
  font-family: Oswald-LightItalic, Arial, sans-serif;
  font-weight: 300;
  font-size: 64px;
  font-size: calc(44px + (64 - 44) * ((100vw - 960px) / (2560 - 960)));
  text-transform: uppercase;
  white-space: nowrap;
}

@media (max-width: 960px) {
  .p-item__title {
    font-size: 44px;
  }
}

@media (min-width: 2560px) {
  .p-item__title {
    font-size: 64px;
  }
}

.p-item__desc {
  color: #fff;
  font-size: 1rem;
  opacity: 0;
  transition: opacity ease-in-out 0.3s;
  min-height: 2.2em;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
}

.p-item.is-active .pause-block {
  display: block;
  opacity: 1;
}

.p-item.is-active .play-svg {
  display: none;
}

.instruments-section {
  padding: 0 10%;
}

.symphony-muted {
  cursor: pointer;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.symphony-muted img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
}

.bubble {
  align-items: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  position: absolute;
  transition: 0.9s;
  justify-content: center;
  overflow: hidden;
  top: 10%;
  left: 5%;
  margin-left: 0;
  margin-top: 0;
  height: 70%;
  width: 33.8%;
}

.bubble canvas {
  margin: 0 auto;
}

.bubble.is-visible {
  opacity: 1;
  visibility: visible;
}

.instruments-list {
  position: absolute;
  z-index: 1;
  left: 9%;
  overflow: hidden;
  opacity: 0;
  bottom: 7rem;
  transform: translateY(0);
  width: 26%;
  padding-bottom: 80px;
  padding-bottom: calc(60px + (80 - 60) * ((100vw - 960px) / (2560 - 960)));
}

@media (max-width: 960px) {
  .instruments-list {
    padding-bottom: 60px;
  }
}

@media (min-width: 2560px) {
  .instruments-list {
    padding-bottom: 80px;
  }
}

.instruments-list_links .instruments-list__item:before {
  content: none;
}

.instruments-list_links .instruments-list__item:not(:last-child) {
  margin-right: 46px;
}

.instruments-list__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.instruments-list__item {
  cursor: pointer;
  height: 36px;
  position: relative;
  width: 36px;
}

.instruments-list__item:not(:last-child) {
  margin-right: 5px;
}

.instruments-list__item:before {
  background-color: #b8bfc3;
  border-radius: 50%;
  height: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color ease 0.3s;
  width: 5px;
}

.instruments-list__item svg {
  fill: #b8bfc3;
  stroke: #b8bfc3;
  transition: stroke ease-in-out 0.3s, fill ease-in-out 0.3s;
}

.instruments-list__item.is-active:before {
  background-color: #d40012;
}

.instruments-list__item.is-active svg {
  fill: #d40012;
  stroke: #d40012;
}

.instruments-list__item:hover:before {
  background-color: #d40012;
}

.instruments-list__item:hover svg {
  fill: #d40012;
  stroke: #d40012;
}

.instruments-list__link {
  height: 42px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
}

.instruments-list__link svg {
  fill: #8fb4ca;
}

.instrument-section {
  display: flex;
  align-items: center;
  padding-top: 100px;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  background-size: cover;
  background-position: bottom center;
}

.instrument-section__title {
  font-size: 70px;
  font-size: calc(50px + (70 - 50) * ((100vw - 960px) / (2560 - 960)));
  text-transform: uppercase;
  font-family: Oswald-LightItalic, Arial, sans-serif;
  font-weight: 300;
  color: #1d3a4e;
  letter-spacing: 4.79px;
  line-height: 1;
  white-space: nowrap;
}

@media (max-width: 960px) {
  .instrument-section__title {
    font-size: 50px;
  }
}

@media (min-width: 2560px) {
  .instrument-section__title {
    font-size: 70px;
  }
}

.instrument-section__content {
  height: 100%;
  padding: 0 10% 6rem 8%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.instrument-section__right {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 65%;
}

.instrument-section__left {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-end;
  width: 33.8%;
}

.instrument-section__gallery {
  margin: auto 0;
}

.instruments-gallery {
  display: flex;
  padding-bottom: 49%;
  position: relative;
  left: 9%;
}

.instruments-gallery__video {
  cursor: pointer;
}

.instruments-gallery__poster {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.instruments-gallery__desc {
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #737373;
  line-height: 1.6;
  letter-spacing: 0.5px;
  padding-right: 14.4%;
  min-height: 4.2em;
  max-width: 70%;
  margin-left: auto;
  width: 100%;
  font-size: 15px;
  font-size: calc(11px + (15 - 11) * ((100vw - 960px) / (2560 - 960)));
}

@media (max-width: 960px) {
  .instruments-gallery__desc {
    font-size: 11px;
  }
}

@media (min-width: 2560px) {
  .instruments-gallery__desc {
    font-size: 15px;
  }
}

.instruments-gallery__item {
  position: absolute;
  overflow: hidden;
}

.instruments-gallery__item img,
.instruments-gallery__item video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.instruments-gallery__item img, .instruments-gallery__item video, .instruments-gallery__item .instruments-gallery__play-btn {
  opacity: 0;
}

.instruments-gallery__item:nth-of-type(1) {
  height: 60%;
  right: auto;
  left: 0;
  width: 61.5%;
}

.instruments-gallery__item:nth-of-type(2) {
  height: 87%;
  right: 14.18%;
  width: 22.4%;
}

.instruments-gallery__item:nth-of-type(3) {
  bottom: 10.9%;
  height: 25.9%;
  left: 9.07%;
  width: 31.9%;
}

.instruments-gallery__item:nth-of-type(4) {
  bottom: 0;
  height: 37%;
  right: 24.1%;
  width: 33.3%;
}

.instruments-gallery__item:nth-of-type(1) .instruments-gallery__item-overlay, .instruments-gallery__item:nth-of-type(4) .instruments-gallery__item-overlay {
  background-color: #26ab94;
}

.instruments-gallery__item:nth-of-type(2) .instruments-gallery__item-overlay {
  background-color: #b8bfc3;
}

.instruments-gallery__item:nth-of-type(3) .instruments-gallery__item-overlay {
  background-color: #ffffff;
}

.instruments-gallery__item-overlay {
  background-color: #ffffff;
  background-image: url("./../img/noise.png");
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  left: 0;
  width: 100%;
}

.is-active-overlay .instruments-gallery img, .is-active-overlay .instruments-gallery video, .is-active-overlay .instruments-gallery .instruments-gallery__play-btn {
  opacity: 1;
}

.instruments-gallery__play-btn {
  cursor: pointer;
  height: 34px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ease-in-out 0.3s;
  width: 25px;
}

.instruments-gallery__play-btn:before {
  content: '';
  position: absolute;
  background: #fff;
  width: 42px;
  height: 42px;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -21px;
  transform: scale(0);
  transition: transform 0.4s;
  transition-delay: 0.15s;
  border-radius: 50%;
}

.instruments-gallery__play-btn:after {
  left: -3px;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(40% 33%, 39% 68%, 69% 49%);
  clip-path: polygon(40% 33%, 39% 68%, 69% 49%);
  background: #4f9a9f;
  transition: opacity, 0.3s;
  opacity: 0;
}

.instruments-gallery__pause-btn {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ease-in-out 0.3s;
}

.instruments-gallery__pause-btn:before, .instruments-gallery__pause-btn:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 24px;
  top: 50%;
  background: #fff;
}

.instruments-gallery__pause-btn:before {
  left: calc(50% - 3px);
  transform: translate(-50%, -50%);
}

.instruments-gallery__pause-btn:after {
  right: calc(50% - 3px);
  transform: translate(50%, -50%);
}

.instruments-gallery:hover .instruments-gallery__item .instruments-gallery__play-btn:before {
  transform: scale(1);
}

.instruments-gallery:hover .instruments-gallery__item .instruments-gallery__play-btn:after {
  opacity: 1;
}

.instruments-gallery .is-playing .instruments-gallery__play-btn {
  opacity: 0;
}

.instruments-gallery .is-playing:hover .instruments-gallery__pause-btn {
  opacity: 1;
}

.symphony__desc {
  margin-bottom: 5px;
}

.symphony__desc p {
  max-width: 340px;
}

.artists-intro {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.artists-intro__preview {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.artists-list {
  top: 50%;
  left: 45%;
  margin-left: -3px;
  transform: translateY(-50%);
  transition: transform 0.5s;
  position: absolute;
  z-index: 1;
}

.artists-list__item {
  position: relative;
  overflow: hidden;
}

.artists-list__item:not(:last-child) {
  margin-bottom: 10px;
}

.artists-list__link {
  display: flex;
  align-items: center;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
}

.artists-list__link .icon {
  opacity: 0;
  visibility: hidden;
}

.artists-list__link:hover span,
.artists-list__link:hover .icon {
  opacity: 1;
}

.artists-list__link:before {
  background-color: #d40012;
  border-radius: 50%;
  height: 5px;
  top: 50%;
  left: 12px;
  transform: translateY(-50%) scale(1);
  width: 5px;
  opacity: 1;
  transition: transform ease-in 0.1s, opacity ease-in 0.1s;
}

.artists-list__link span {
  margin-left: 70px;
  font-size: 60px;
  font-size: 80px;
  font-size: calc(52px + (80 - 52) * ((100vw - 960px) / (2560 - 960)));
  color: #fff;
  text-transform: uppercase;
  font-family: Oswald-LightItalic, Arial, sans-serif;
  font-weight: 300;
  transition: opacity 0.3s;
}

@media (max-width: 960px) {
  .artists-list__link span {
    font-size: 52px;
  }
}

@media (min-width: 2560px) {
  .artists-list__link span {
    font-size: 80px;
  }
}

.artists-list__link .icon {
  display: block;
  font-size: 28px;
  fill: #d40012;
  transition: transform 0.3s, fill 0.3s,opacity 0.3s;
  transform: scale(0);
}

.artists-list__link span,
.artists-list__link .icon {
  opacity: 0.35;
}

.artists-list__timeline {
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  width: 100%;
}

.artists-list__timeline svg {
  width: 100%;
  height: 100%;
}

.artists-list.is-scrolled {
  overflow: hidden;
  top: auto;
  bottom: 6rem;
  transform: translateY(0);
  left: 4%;
  width: 45%;
}

.artists-list.is-scrolled .artists-list__wrapper {
  display: flex;
  justify-content: center;
}

.artists-list.is-scrolled .artists-list__item:not(:last-child) {
  margin-bottom: 12px;
}

.artists-list.is-scrolled .artists-list__link {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.artists-list.is-scrolled .artists-list__link:before {
  background-color: #b8bfc3;
  left: 50%;
  margin-left: -2px;
  transition: background-color ease-in-out 0.3s;
}

.artists-list.is-scrolled .artists-list__link .icon {
  fill: #fff;
  font-size: 20px;
  transform: scale(1);
}

.artists-list.is-scrolled .artists-list__link:hover:before {
  background-color: #d40012;
}

.artists-list.is-scrolled .artists-list__link:hover .icon {
  fill: #e42c2c;
}

.artists-list.is-scrolled .artists-list__link.is-active:before {
  background-color: #d40012;
}

.artists-list.is-scrolled .artists-list__link.is-active .artists-list__timeline {
  opacity: 1;
}

.artists-list.is-scrolled .artists-list__link.is-active .icon {
  fill: #e42c2c;
  transform: scale(0.7);
}

.artists-list.is-scrolled .icon {
  opacity: 1;
}

.artists-list.is-scrolled span {
  display: none;
}

.artist-section-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-45%, -45%) scale(1.2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.artist-section {
  display: flex;
  padding-top: 100px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.artist-section__content {
  height: 100%;
  padding: 0 10% 11.5rem 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.artist-section__left, .artist-section__right {
  height: 100%;
  width: 100%;
}

.artist-section__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.artist-section__right {
  display: flex;
}

.is-content-hidden .artist-section__right {
  opacity: 0;
}

.artist-section__title {
  font-family: Oswald-LightItalic, Arial, sans-serif;
  font-weight: 300;
  font-size: 55px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.08px;
  line-height: 1;
  color: #ffffff;
}

.artist-section_dakha .artist-section__desc {
  max-width: 420px;
}

.artist-section_maneken .artist-section__desc, .artist-section_chilly .artist-section__desc {
  max-width: 440px;
}

.artist-gallery {
  align-self: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 94%;
}

.artist-gallery__desc {
  color: #414141;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
  padding-left: 10%;
  line-height: 1.5;
  min-height: 3.5em;
  font-size: 16px;
  font-size: calc(14px + (16 - 14) * ((100vw - 960px) / (2560 - 960)));
}

@media (max-width: 960px) {
  .artist-gallery__desc {
    font-size: 14px;
  }
}

@media (min-width: 2560px) {
  .artist-gallery__desc {
    font-size: 16px;
  }
}

.artist-gallery--chilly .artist-gallery__desc, .artist-gallery--chilly2 .artist-gallery__desc, .artist-gallery--maneken .artist-gallery__desc, .artist-gallery--maneken2 .artist-gallery__desc {
  color: #FFFFFF;
}

.artist-gallery__items {
  width: 100%;
  padding-top: 70%;
  position: relative;
  margin: auto 0;
}

.artist-gallery__item:nth-of-type(1) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery__item:nth-of-type(4) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery__item:nth-of-type(2) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery__item:nth-of-type(5) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery__item:nth-of-type(3) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery__item:nth-of-type(6) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery__item-overlay {
  background-color: #ffffff;
  background-image: url("./../img/noise.png");
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-101%);
  left: 0;
  width: 100%;
}

.is-active-overlay .artist-gallery img, .is-active-overlay .artist-gallery video {
  opacity: 1;
}

.artist-gallery__item {
  position: absolute;
  overflow: hidden;
}

.artist-gallery__item img, .artist-gallery__item video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
}

.artist-gallery__item:nth-child(1) {
  top: 0;
  left: 0;
  width: 50%;
  height: 33%;
}

.artist-gallery__item:nth-child(1) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery__item:nth-child(2) {
  bottom: 0;
  left: 0;
  width: 50%;
  height: 33%;
}

.artist-gallery__item:nth-child(2) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery__item:nth-child(3) {
  right: 0;
  top: 0;
  height: 100%;
  width: 44%;
}

.artist-gallery__item:nth-child(3) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery__item:nth-child(4) {
  top: 50%;
  left: 20%;
  width: 40%;
  height: 25%;
}

.artist-gallery__item:nth-child(4) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(1) {
  top: 0;
  left: 12%;
  width: 37.7%;
  height: 23.6%;
  z-index: 1;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(1) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(2) {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  width: 37.7%;
  height: 23.6%;
  z-index: 1;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(2) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(3) {
  bottom: auto;
  top: 28%;
  left: 0;
  right: auto;
  height: 56%;
  width: 80%;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(3) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(4) {
  top: 0;
  left: auto;
  right: 0;
  width: 47%;
  height: 31.1%;
}

.artist-gallery--onuka .artist-gallery__item:nth-child(4) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery--onuka2 .artist-gallery__item:nth-of-type(4) {
  transform: translateY(-50%);
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(1) {
  transform: none;
  top: 0;
  left: 0;
  width: 68.7%;
  height: 52%;
  z-index: 0;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(1) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(2) {
  top: 43.6%;
  left: 15.4%;
  width: 44%;
  height: 30%;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(2) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(3) {
  bottom: auto;
  top: 0;
  left: auto;
  right: 0;
  height: 61.5%;
  width: 27.8%;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(3) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(4) {
  top: 65%;
  left: auto;
  right: 0;
  width: 38%;
  height: 24.6%;
}

.artist-gallery--dahabraha .artist-gallery__item:nth-child(4) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--dahabraha2 .artist-gallery__item:nth-child(1) {
  height: 57.4%;
  top: 29.4%;
  width: 80%;
}

.artist-gallery--dahabraha2 .artist-gallery__item:nth-child(2) {
  top: 0;
  left: 21.5%;
  height: 32%;
  bottom: auto;
  width: 48%;
}

.artist-gallery--dahabraha2 .artist-gallery__item:nth-child(3) {
  height: 57.7%;
  width: 27%;
}

.artist-gallery--dahabraha2 .artist-gallery__item:nth-child(4) {
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(1) {
  top: 0;
  left: 30%;
  width: 39.5%;
  height: 24.8%;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(1) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(2) {
  bottom: auto;
  top: 0;
  left: auto;
  right: 0;
  height: 58.6%;
  width: 27.2%;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(2) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(3) {
  top: auto;
  left: 0;
  bottom: 12%;
  width: 80.7%;
  height: 58.6%;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(3) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(4) {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  width: 39.5%;
  height: 24.8%;
}

.artist-gallery--maneken .artist-gallery__item:nth-child(4) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--maneken2 .artist-gallery__item:nth-child(1) {
  height: 24%;
  left: 30.78%;
  width: 39%;
}

.artist-gallery--maneken2 .artist-gallery__item:nth-child(3) {
  bottom: auto;
  top: 0;
  left: auto;
  right: 0;
  height: 58.6%;
  width: 27.2%;
}

.artist-gallery--maneken2 .artist-gallery__item:nth-child(3) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery--maneken2 .artist-gallery__item:nth-child(2) {
  top: auto;
  left: 0;
  bottom: 13.8%;
  width: 80%;
  height: 57.7%;
}

.artist-gallery--maneken2 .artist-gallery__item:nth-child(2) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery--maneken2 .artist-gallery__item:nth-child(4) {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(1) {
  top: 0;
  left: 18%;
  width: 39.5%;
  height: 24.8%;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(1) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(2) {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  width: 49.2%;
  height: 32.6%;
  z-index: 1;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(2) .artist-gallery__item-overlay {
  background-color: #ffffff;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(3) {
  bottom: auto;
  top: 28%;
  left: 0;
  right: auto;
  height: 58.6%;
  width: 80.7%;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(3) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(4) {
  top: 0;
  left: auto;
  right: 0;
  width: 39.5%;
  height: 24.8%;
}

.artist-gallery--chilly .artist-gallery__item:nth-child(4) .artist-gallery__item-overlay {
  background-color: #26ab94;
}

.artist-gallery--chilly2 .artist-gallery__item:nth-child(1) {
  top: 0;
  left: 0;
  width: 49.2%;
  height: 32.6%;
}

.artist-gallery--chilly2 .artist-gallery__item:nth-child(1) .artist-gallery__item-overlay {
  background-color: #b8bfc3;
}

.artist-gallery--chilly2 .artist-gallery__item:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  left: 20%;
  width: 40%;
  height: 25%;
  z-index: 1;
}

.artist-gallery--chilly2 .artist-gallery__item:nth-child(3) {
  top: auto;
  bottom: 0;
  left: 0;
  width: 49.2%;
  height: 32.6%;
}

.artist-gallery--chilly2 .artist-gallery__item:nth-child(4) {
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
  width: 45%;
}

.btn {
  display: inline-block;
  font-size: 14px;
  padding: 10px 23px 10px;
  border-radius: 21px;
  background-color: #ffffff;
  white-space: nowrap;
  color: rgba(69, 69, 69, 0.73);
  letter-spacing: 0.5px;
  opacity: 0.73;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
}

.btn__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__icon {
  margin-right: 10px;
  width: 14px;
  height: 15px;
  opacity: 0.77;
}

.btn__icon svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.mute-btn {
  background-color: transparent;
}

.mute-btn .icon {
  font-size: 24px;
  stroke: #535353;
}

.video-link {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  z-index: 11;
  position: absolute;
  top: 26px;
  right: 14%;
  display: flex;
  color: #68b4d7;
  justify-content: stretch;
}

.video-link__icon {
  display: block;
  max-height: 100%;
  min-height: 100%;
}

.video-link__icon svg {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
}

.video-link__text {
  font-family: Roboto-ThinItalic, Arial, sans-serif;
  font-weight: 100;
  font-size: 15px;
  letter-spacing: 1.79px;
  text-align: right;
  margin-right: 10px;
  font-size: 15px;
  font-size: calc(10px + (15 - 10) * ((100vw - 960px) / (2560 - 960)));
}

@media (max-width: 960px) {
  .video-link__text {
    font-size: 10px;
  }
}

@media (min-width: 2560px) {
  .video-link__text {
    font-size: 15px;
  }
}

.video-link--light {
  color: #fff;
}

.video-link.is-visible {
  opacity: 1;
  visibility: visible;
}

.product-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.product-section.section-fp-inner {
  padding-top: 0;
  padding-bottom: 0;
}

.product-section__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.product-section__text-part {
  margin-left: 6.94444%;
  position: relative;
  z-index: 1;
  width: 41.66667%;
  padding-top: 3%;
}

.product-section__title {
  color: #41505b;
  font-size: 80px;
  font-size: calc(44px + (80 - 44) * ((100vw - 960px) / (2560 - 960)));
  font-family: Oswald-LightItalic, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;
  opacity: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (max-width: 960px) {
  .product-section__title {
    font-size: 44px;
  }
}

@media (min-width: 2560px) {
  .product-section__title {
    font-size: 80px;
  }
}

@media only screen and (max-width: 1260px) {
  .product-section__title {
    font-size: 80px;
    font-size: calc(30px + (80 - 30) * ((100vw - 960px) / (2560 - 960)));
  }
}

@media only screen and (max-width: 1260px) and (max-width: 960px) {
  .product-section__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1260px) and (min-width: 2560px) {
  .product-section__title {
    font-size: 80px;
  }
}

.product-section__title .letter {
  min-width: 36px;
  min-width: calc(20px + (36 - 20) * ((100vw - 960px) / (2560 - 960)));
}

@media (max-width: 960px) {
  .product-section__title .letter {
    min-width: 20px;
  }
}

@media (min-width: 2560px) {
  .product-section__title .letter {
    min-width: 36px;
  }
}

.product-section__text {
  color: #2f2f2f;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 23px;
  font-size: calc(14px + (23 - 14) * ((100vw - 960px) / (2560 - 960)));
  opacity: 0;
  margin-bottom: 50px;
}

@media (max-width: 960px) {
  .product-section__text {
    font-size: 14px;
  }
}

@media (min-width: 2560px) {
  .product-section__text {
    font-size: 23px;
  }
}

.product-section__btn-wrapper {
  text-align: right;
  font-size: 16px;
  padding-right: 20px;
  opacity: 0;
}

.product-section__btn-wrapper a {
  display: inline-flex;
  align-items: center;
}

.product-section__btn-wrapper a img {
  width: 18px;
  height: 12px;
  opacity: 0.77;
  margin-left: 5px;
}

.product-section__footer {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 4%;
  padding-left: 6.94444%;
  opacity: 0;
}

.product-section__footer p {
  font-size: 20px;
  font-size: calc(6px + (20 - 6) * ((100vw - 960px) / (2560 - 960)));
  color: #5d5d5d;
  opacity: 0.68;
  display: inline-block;
  vertical-align: top;
  letter-spacing: 0.4px;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
}

@media (max-width: 960px) {
  .product-section__footer p {
    font-size: 6px;
  }
}

@media (min-width: 2560px) {
  .product-section__footer p {
    font-size: 20px;
  }
}

.product-section__footer p:not(:last-of-type) {
  margin-right: 20px;
}

.product-section__footer a {
  transition: color 0.3s;
}

.product-section__footer a:hover {
  color: #000;
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
}

.charity-block {
  align-items: flex-end;
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  opacity: 0;
}

.charity-block__img {
  flex-shrink: 0;
  padding-right: 20px;
  width: 155px;
}

.charity-block__img img {
  max-width: 125px;
  height: auto;
}

.charity-block__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 54px;
  color: #2f2f2f;
  font-size: 23px;
  font-size: calc(14px + (23 - 14) * ((100vw - 960px) / (2560 - 960)));
}

@media (max-width: 960px) {
  .charity-block__text {
    font-size: 14px;
  }
}

@media (min-width: 2560px) {
  .charity-block__text {
    font-size: 23px;
  }
}

.charity-block__logos-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 120px;
  max-width: 120px;
  padding-bottom: 5px;
}

.charity-block__logo {
  display: block;
}

.charity-block__logo:last-of-type {
  height: auto;
  width: 100%;
}

.charity-block__logo:not(:last-of-type) {
  height: 40px;
  margin-bottom: 26px;
}

.product-gallery {
  width: 100%;
}

.product-gallery__desc {
  color: #414141;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
}

.product-gallery__desc--white {
  color: #FFFFFF;
}

.product-gallery__items {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 30px;
}

.product-gallery__item:not(:last-child) {
  margin-bottom: 10px;
}

.product-gallery__item:nth-of-type(1) .product-gallery__item-overlay {
  background-color: #fff;
}

.product-gallery__item:nth-of-type(2) .product-gallery__item-overlay {
  background-color: #26ab94;
}

.product-gallery__item-overlay {
  background-color: #ffffff;
  background-image: url("./../img/noise.png");
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-101%);
  left: 0;
  width: 100%;
}

.product-gallery__media {
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.product-gallery__item {
  position: absolute;
  overflow: hidden;
}

.product-gallery__item img, .product-gallery__item video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.product-gallery__item img.is-visible, .product-gallery__item video.is-visible {
  opacity: 1;
}

.product-gallery__item img {
  width: 117%;
  top: 52.2%;
  transform: translateY(-50%);
  height: 116%;
}

.product-gallery__item img:nth-child(2) {
  height: 114%;
  top: 51.4%;
}

.product-gallery__item img:nth-child(3) {
  height: 114%;
  top: 51.2%;
}

.product-gallery__item img:nth-child(4) {
  height: 117%;
  top: 51.6%;
}

.product-gallery__item:nth-child(1) {
  top: 19%;
  right: 28.6%;
  width: 18.05556%;
  height: 61.42857%;
  z-index: 1;
}

.product-gallery__item:nth-child(1) .product-gallery__item-overlay {
  background-color: #ffffff;
}

.product-gallery__item:nth-child(2) {
  top: 0%;
  right: 10%;
  width: 23.61111%;
  height: 100%;
}

.product-gallery__item:nth-child(2) .product-gallery__item-overlay {
  background-color: #38a976;
}

.voice-btn {
  background-color: transparent;
  color: #c92b1e;
  font-size: 1rem;
  padding-left: 10px;
  position: fixed;
  top: 34px;
  right: 3.8%;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  transition: opacity ease-in-out 0.3s;
  z-index: 8;
  font-family: Oswald-RegularItalic, Arial, sans-serif;
  font-weight: 400;
}

.voice-btn:before {
  background-color: #d60000;
  border-radius: 50%;
  height: 3px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
}

.voice-btn.is-active {
  color: #68b4d7;
}

.preloader {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 99;
  background-color: #fff;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
}

.preloader__wave {
  padding-bottom: 28%;
  width: 30%;
  position: relative;
}

.preloader__wave canvas {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.preloader__value-wrapper {
  max-width: 500px;
  width: 100%;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.preloader__percentage {
  margin-top: 10px;
  text-align: right;
  color: #7d8d92;
}

.preloader__value {
  transition: 0.3s;
  max-width: 500px;
  width: 0;
  height: 100%;
  background-color: #dedede;
}

.preloader.is-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -100;
}

.step {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 160px;
  z-index: 7;
  overflow: hidden;
  font-size: 14px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.step:not(.is-olways-visible) {
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
}

.step:not(.is-olways-visible) .step__overlay {
  transform: translateX(100%);
}

.step:not(.is-olways-visible).is-active {
  visibility: visible;
  opacity: 1;
}

.step:not(.is-olways-visible).is-active .step__overlay {
  transform: translateX(0);
}

.step__btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 18px 64px 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.step__btns.is-hovered .step__btn--light {
  color: #404040;
}

.step__btns.is-hovered .step__btn--light .step__btn-line {
  background-color: #404040;
}

.step__btn-line {
  display: block;
  flex-grow: 1;
  margin-right: 13px;
  position: relative;
  height: 3px;
  border-radius: 15%;
  overflow: hidden;
}

.step__btn-line:before {
  content: "";
  position: absolute;
  top: 0;
  border-radius: 50%;
  background: #e00926;
  width: 3px;
  height: 3px;
}

.step__btn-line:after {
  content: "";
  position: absolute;
  width: calc(100% - 7px);
  top: 50%;
  height: 1px;
  margin-top: -1px;
  opacity: 0.34;
  background-color: #444;
  transition: transform 0.3s;
}

.step__btn-line.is-animated:before {
  animation: nextStep 1.3s infinite;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.step__btn-line.is-animated:after {
  display: none;
}

.step__btn {
  display: flex;
  align-items: center;
  width: 100%;
  color: #535353;
  font-family: Roboto-LightItalic, Arial, sans-serif;
  font-weight: 300;
  position: relative;
  letter-spacing: 1px;
}

.step__btn + .step__btn {
  margin-top: 10px;
}

.step__btn span[data-hover-transform] {
  position: relative;
  transition: color 0.2s;
}

.step__btn span[data-hover-transform]:before {
  content: attr(data-hover-transform);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #d90b30;
  transition: transform 0.3s, opacity 0.3s;
  font-family: Roboto-Italic, Arial, sans-serif;
  font-weight: 400;
  opacity: 0;
}

.step__btn--light {
  color: #e7f1f5;
}

.step__btn--light .step__btn-line:after {
  background-color: #dfebef;
}

.step__btn--next span[data-hover-transform]:before {
  transform: translate(-2px, 0px);
}

.step__btn--next .step__btn-line:after {
  right: 0;
}

.step__btn--next:hover span[data-hover-transform] {
  color: transparent;
}

.step__btn--next:hover span[data-hover-transform]:before {
  transform: translate(0px, 0px);
  opacity: 1;
}

.step__btn--next:hover .step__btn-line:before {
  animation: nextStep 1.3s infinite;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.step__btn--next:hover .step__btn-line:after {
  transform: translateX(100%);
}

.step__btn--back span[data-hover-transform]:before {
  transform: translate(2px, 0px);
}

.step__btn--back .step__btn-line {
  order: 2;
  margin-right: 0;
  margin-left: 13px;
}

.step__btn--back .step__btn-line:before {
  right: 0;
  left: auto;
  animation: none;
}

.step__btn--back .step__btn-line:after {
  left: 0;
}

.step__btn--back:hover span[data-hover-transform] {
  color: transparent;
}

.step__btn--back:hover span[data-hover-transform]:before {
  transform: translate(0px, 0px);
  opacity: 1;
}

.step__btn--back:hover .step__btn-line:before {
  animation: prevStep 1.3s infinite;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.step__btn--back:hover .step__btn-line:after {
  transform: translateX(-100%);
}

.step__overlays {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -2;
}

.step__overlay {
  transition: transform ease-out 0.4s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.step__overlay[data-step="back"] {
  opacity: 0;
}

.step__overlay--double-width {
  width: 200%;
}

.step.is-olways-visible {
  width: 10%;
}

.step.is-olways-visible .step__btns {
  padding: 40px 20px 66px 15px;
}

.step:hover .step__overlay[data-step="next"] {
  transform: translateX(-50%) !important;
}

.step-fixed-btn {
  letter-spacing: -0.05px;
  position: fixed;
  bottom: 63px;
  right: 20px;
  max-width: 127px;
  display: inline-flex;
  z-index: 8;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
}

.step-fixed-btn.is-visible {
  opacity: 1;
}

.step-fixed-btn:hover:hover + .step .step__overlay[data-step="next"] {
  transform: translateX(-50%) !important;
}

.step-back-overlay {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 150%;
  background-color: #FFFFFF;
  opacity: 1;
  transform: translateX(-100%);
}

.symphony-gallery {
  padding-bottom: 53.8%;
  position: relative;
  width: 95%;
}

.symphony-gallery__item {
  cursor: pointer;
  position: absolute;
  overflow: hidden;
}

.symphony-gallery__item:hover .symphony-gallery__image {
  transform: scale(1.2);
}

.symphony-gallery__item:nth-child(1) {
  top: 8.1%;
  padding-bottom: 38.1%;
  width: 24.3%;
}

.symphony-gallery__item:nth-child(2) {
  left: 26.1%;
  padding-bottom: 23.1%;
  width: 40%;
}

.symphony-gallery__item:nth-child(3) {
  top: 8.1%;
  right: 0;
  padding-bottom: 20%;
  width: 32.3%;
}

.symphony-gallery__item:nth-child(4) {
  top: 45%;
  left: 21.5%;
  padding-bottom: 29.7%;
  width: 24.9%;
}

.symphony-gallery__item:nth-child(5) {
  top: 45%;
  left: 47.7%;
  padding-bottom: 29.7%;
  width: 18.3%;
}

.symphony-gallery__item:nth-child(6) {
  top: 47.7%;
  right: 0;
  padding-bottom: 17.4%;
  width: 32.3%;
}

.symphony-gallery__image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  transform: scale(1);
  transition: transform ease 1s;
  width: 100%;
}

.cookies-note {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 16px rgba(191, 195, 214, 0.64);
  color: #7f9293;
  font-size: 11px;
  color: #7f9293;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
  z-index: 10000;
}

.cookies-note__container {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.cookies-note__text, .cookies-note__btns {
  margin-bottom: 10px;
}

.cookies-note__text {
  padding: 8px;
  flex-shrink: 0;
  max-width: calc(100% - 260px);
}

.cookies-note__btns {
  display: flex;
  align-items: flex-start;
  width: 260px;
  flex-shrink: 0;
}

.cookies-note__btn {
  display: block;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 14px;
  transition: background-color 0.2s;
}

.cookies-note__btn:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.cookies-note__btn_link.cookies-note__btn {
  border-radius: 0;
  background-color: transparent;
  text-decoration: underline;
}

.cookies-note__btn:not(:last-child) {
  margin-right: 0px;
}

.cookies-note.is-active {
  transform: translateY(0);
  opacity: 1;
}
