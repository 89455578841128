.cookies-note {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	font-size: 40px;
	background-color: rgba(#fff, 0.9);
	box-shadow: 0 4px 16px rgba(191, 195, 214, 0.64);
	color: #7f9293;
	font-size: 11px;
	color: #7f9293;
	font-family: 'Roboto', sans-serif;
	font-style: italic;
	transform: translateY(100%);
	opacity: 0;
	transition: transform 0.5s, opacity 0.5s;
	z-index: 10000;
	&__container {
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 10px;
		max-width: 1220px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between; }
	&__text, &__btns {
		margin-bottom: 10px; }
	&__text {
		padding: 8px;
		flex-shrink: 0;
		max-width: calc(100% - 260px); }
	&__btns {
		display: flex;
		align-items: flex-start;
		width: 260px;
		flex-shrink: 0; }
	&__btn {
		display: block;
		color: inherit;
		font-size: inherit;
		font-family: inherit;
		font-style: inherit;
		background-color: #fff;
		padding: 8px 20px;
		border-radius: 14px;
		transition: background-color 0.2s;
		&:hover {
			background-color: rgba(#fff, 0.6); }
		&_link.cookies-note__btn {
			border-radius: 0;
			background-color: transparent;
			text-decoration: underline;
			&:hover {
				background-color:transparent {} } }
		&:not(:last-child) {
			margin-right: 0px; } }
	&.is-active {
		transform: translateY(0);
		opacity: 1; } }

