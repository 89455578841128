.instruments-section {
	padding: 0 10%; }

.symphony-muted {
	cursor: pointer;
	height: 100%;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 130px; } }

.bubble {
	align-items: center;
	display: flex;
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	position: absolute;
	// top: 50%
	// left: 45%
	transition: 0.9s;
	justify-content: center;
	overflow: hidden;
	// margin-left: -20vw
	// margin-top: -15vw
	top: 10%;
	left: 5%;
	margin-left: 0;
	margin-top: 0;
	height: 70%;
	width: 33.8%;
	canvas {
		margin: 0 auto; }
	&.is-visible {
		opacity: 1;
		visibility: visible; }
	// &.is-scrolled
	// 	top: 10%
	// 	left: 5%
	// 	margin-left: 0
	// 	margin-top: 0
	// 	height: 70%
 }	// 	width: 33.8%

.instruments-list {
	$this: &;
	position: absolute;
	z-index: 1;
	left: 9%;
	overflow: hidden;
	opacity: 0;
	bottom: 7rem;
	transform: translateY(0);
	width: 26%;
	@include css-lock(60, 80, 960, 2560, 'padding-bottom');
	&_links {
		#{$this} {
			&__item {
				&:before {
					content: none; }
				&:not(:last-child) {
					margin-right: 46px; } } } }
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center; }
	&__item {
		cursor: pointer;
		height: 36px;
		position: relative;
		width: 36px;
		&:not(:last-child) {
			margin-right: 5px; }
		&:before {
			@include pseudo;
			background-color: #b8bfc3;
			border-radius: 50%;
			height: 5px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: background-color ease 0.3s;
			width: 5px; }
		svg {
			fill: #b8bfc3;
			stroke: #b8bfc3;
			transition: stroke ease-in-out 0.3s, fill ease-in-out 0.3s; }
		&.is-active {
			&:before {
				background-color: #d40012; }
			svg {
				fill: #d40012;
				stroke: #d40012; } }
		&:hover {
			&:before {
				background-color: #d40012; }
			svg {
				fill: #d40012;
				stroke: #d40012; } } }
	&__link {
		height: 42px;
		font-size: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
		svg {
			fill: #8fb4ca;
 } } }			// transition: fill ease-in-out 0.3s

.instrument-section {
	display: flex;
	align-items: center;
	padding-top: 100px;
	height: 100%;
	width: 100%;
	background-color: #FFFFFF;
	background-size: cover;
	background-position: bottom center;
	&__title {
		@include css-lock(50, 70, 960, 2560);
		text-transform: uppercase;
		@include ligthItalic;
		color: #1d3a4e;
		letter-spacing: 4.79px;
		line-height: 1;
		white-space: nowrap; }
	&__content {
		height: 100%;
		padding: 0 10% 6rem 8%;
		display: flex;
		justify-content: space-between;
		width: 100%; }
	&__right {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: space-between;
		width: 65%; }
	&__left {
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: flex-end;
		width: 33.8%; }
	&__gallery {
		margin: auto 0; } }

.instruments-gallery {
	$this: &;
	display: flex;
	padding-bottom: 49%;
	position: relative;
	left: 9%;
	&__video {
		cursor: pointer; }
	&__poster {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%; }
	&__desc {
		@include RobotoLightItalic;
		position: absolute;
		bottom: 0;
		right: 0;
		color: #737373;
		line-height: 1.6;
		letter-spacing: 0.5px;
		padding-right: 14.4%;
		min-height: 4.2em;
		max-width: 70%;
		margin-left: auto;
		width: 100%;
		@include css-lock(11, 15, 960, 2560); }
	&__item {
		position: absolute;
		overflow: hidden;
		img,
		video {
			height: 100%;
			object-fit: cover;
			width: 100%; }
		img, video, #{$this}__play-btn {
			opacity: 0; }
		&:nth-of-type(1) {
			height: 60%;
			right: auto;
			left: 0;
			width: 61.5%; }
		&:nth-of-type(2) {
			height: 87%;
			right: 14.18%;
			width: 22.4%; }
		&:nth-of-type(3) {
			bottom: 10.9%;
			height: 25.9%;
			left: 9.07%;
			width: 31.9%; }
		&:nth-of-type(4) {
			bottom: 0;
			height: 37%;
			right: 24.1%;
			width: 33.3%; }
		&:nth-of-type(1),
		&:nth-of-type(4) {
			#{$this}__item-overlay {
				background-color: #26ab94; } }
		&:nth-of-type(2) {
			#{$this}__item-overlay {
				background-color: #b8bfc3; } }
		&:nth-of-type(3) {
			#{$this}__item-overlay {
				background-color: #ffffff; } } }
	&__item-overlay {
		background-color: #ffffff;
		background-image: url('./../img/noise.png');
		height: 100%;
		position: absolute;
		top: 0;
		transform: translateY(-100%);
		left: 0;
		width: 100%; }
	.is-active-overlay & {
		img, video, #{$this}__play-btn {
			opacity: 1; } }
	&__play-btn {
		cursor: pointer;
		height: 34px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: opacity ease-in-out 0.3s;
		width: 25px;
		&:before {
			content: '';
			position: absolute;
			background: #fff;
			width: 42px;
			height: 42px;
			left: 50%;
			top: 50%;
			margin-left: -25px;
			margin-top: -21px;
			transform: scale(0);
			transition: transform 0.4s;
			transition-delay: 0.15s;
			border-radius: 50%; }
		&:after {
			@include pseudo;
			left: -3px;
			width: 100%;
			height: 100%;
			-webkit-clip-path: polygon(40% 33%,39% 68%,69% 49%);
			clip-path: polygon(40% 33%,39% 68%,69% 49%);
			background: #4f9a9f;
			transition: opacity, 0.3s;
			opacity: 0; } }
	&__pause-btn {
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: opacity ease-in-out 0.3s;
		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 3px;
			height: 24px;
			top: 50%;
			background: #fff; }
		&:before {
			left: calc(50% - 3px);
			transform: translate(-50%, -50%); }
		&:after {
			right: calc(50% - 3px);
			transform: translate(50%, -50%); } }
	&:hover {
		#{$this}__item {
			#{$this}__play-btn {
				&:before {
					transform: scale(1); }
				&:after {
					opacity: 1; } } } }
	.is-playing {
		#{$this} {
			&__play-btn {
				opacity: 0; } }
		&:hover {
			#{$this} {
				&__pause-btn {
					opacity: 1; } } } } }

.symphony {
	$this: &;
	&__desc {
		margin-bottom: 5px;
		p {
			max-width: 340px; } }
	// height: 100%
	// width: 100%
	// background-repeat: no-repeat
	// background-size: cover
	// background-position: center
	// padding: 0 10%
	// position: relative
	// display: flex
	// align-items: flex-end
	// justify-content: center
	// margin-left: -5%
	// &__content
	// 	height: 100%
	// 	align-items: flex-end
	// 	display: flex
	// 	justify-content: center
	// 	position: relative
	// 	margin: 0 auto
	// 	padding-bottom: 1.5rem
	// 	width: 60%
	// &__title
	// 	color: #1e3a4e
	// 	opacity: 0
	// 	margin-right: 11.6rem
	// 	letter-spacing: 0.2rem
	// 	text-transform: uppercase
	// 	text-align: right
	// 	+css-lock(36, 55, 960, 2560)
	// 	+ligthItalic
	// 	z-index: 1
	// &__desc
	// 	color: #2f2f2f
	// 	opacity: 0
	// 	line-height: 1.6
	// 	white-space: nowrap
	// 	+RobotoLightItalic
	// 	z-index: 1
	// 	+css-lock(12, 15, 960, 2560)
	// &__title,
	// &__desc
	// 	min-width: 50%
	// &__icons-circle
	// 	height: 100%
	// 	top: 0
	// 	left: 0
	// 	position: absolute
	// 	width: 100%
	// &__txt
	// 	align-items: center
	// 	display: flex
	// 	justify-content: center

	// $circle-size: 35vw
	// $nb-items: 20
	// $rot: 0
	// $angle: (360 / $nb-items)
	// &__icon
	// 	align-items: center
	// 	display: flex
	// 	height: 46px
	// 	font-size: 30px
	// 	top: 50%
	// 	left: 50%
	// 	position: absolute
	// 	margin: -25px
	// 	justify-content: center
	// 	width: 46px
	// 	z-index: 1
	// 	svg
	// 		fill: #9ac7dc
	// 	svg:not(.icon)
	// 		height: 100%
	// 	@for $i from 1 through $nb-items + 1
	// 		  &:nth-child(#{$i})
	// 		    transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg)
	// 		  $rot: ($rot + $angle)
	// 	&_link
	// 		cursor: pointer
	// 	&_media
	// 		box-sizing: content-box
	// 		padding-left: 10px
	// 		&:not(#{$this}_first-media)
	// 			padding-top: 10px
	// &__btns
	// 	background-color: #fff
	// 	border-radius: 50%
	// 	height: 45px
	// 	position: absolute
	// 	top: 50%
	// 	left: 50%
	// 	transform: translate(-50%, -50%)
	// 	width: 45px
	// 	z-index: 1
	// 	transition: background-color ease-in-out 0.3s
	// 	&.is-paused
	// 		#{$this}__btn
	// 			&--play
	// 				opacity: 1
	// 			&--pause
	// 				opacity: 0
	// 	&.is-playing
	// 		background-color: transparent
	// 		#{$this}__btn
	// 			opacity: 0
	// 		&.is-hovered
	// 			background-color: #fff
	// 			#{$this}__btn
	// 				opacity: 1
	// 				&--play
	// 					opacity: 0
	// &__btn
	// 	background-color: transparent
	// 	height: 100%
	// 	opacity: 1
	// 	position: absolute
	// 	top: 0
	// 	transition: opacity ease-in-out 0.3s
	// 	left: 0
	// 	width: 100%
	// 	svg
	// 		font-size: 15px
	// 		fill: #68b4d7
	// 		position: relative
	// 		top: 1px
	// 	&--play
	// 		opacity: 0
	// 		svg
	// 			left: 2px
	/// 	&--main
	// 		font-size: 25px
	// 		padding: 0 15px
	// 		position: relative
	// 		&:before
	// 			+pseudo
	// 			background-color: #b6d7e5
	// 			height: 35%
	// 			top: 50%
	// 			transform: translateY(-50%)
	// 			left: 100%
	// 			width: 1px
	// 	&.is-active
	// 		.st0,
	// 		.st1,
	// 		.st2,
	// 		.st1-6,
	// 		.st2-6,
	// 		.st0-3,
	// 		.st0-4,
	// 		.st0-5,
	// 		svg
	// 			fill: #e42c2c
	// 		.st2_fill-none
	// 			fill: none
	// 	&:hover
	// 		.st0,
	// 		.st1,
	// 		.st2,
	// 		.st1-6,
	// 		.st2-6,
	// 		.st0-3,
	// 		.st0-4,
	// 		.st0-5,
	// 		svg
	// 			fill: #e42c2c
	// 		.st2_fill-none
	// 			fill: none
	// 	&.is-active
	// 		transform: scale(0.7)
	// .is-scrolled + &
	// 	opacity: 1
	// &__icon
	// 	align-items: center
	// 	display: flex
	// 	height: 46px
	// 	font-size: 30px
	// 	top: 50%
	// 	left: 50%
	// 	position: absolute
	// 	margin: -25px
	// 	justify-content: center
	// 	width: 46px
	// 	z-index: 1
	// 	svg
 }	// 		fill: #9ac7dc
