.artists-intro {
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&__preview {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transform: scale(1.2);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center; } }

.artists-list {
	$this: &;
	top: 50%;
	left: 45%;
	margin-left: -3px;
	transform: translateY(-50%);
	transition: transform 0.5s;
	position: absolute;
	z-index: 1;
	&__item {
		position: relative;
		overflow: hidden;
		&:not(:last-child) {
			margin-bottom: 10px; } }
	&__link {
		display: flex;
		align-items: center;
		position: relative;
		opacity: 0;
		transition: opacity 0.3s;
		.icon {
			opacity: 0;
			visibility: hidden; }
		// &:hover
		/// &:before
		/// 	background-color: #d40012
		//// opacity: 0
		//// transform: scale(2)
		/// .icon
		/// 	fill: #fff
		/// 	transform: scale(1)
		&:hover {
			span,
			.icon {
				opacity: 1; } }
		&:before {
			@include pseudo;
			background-color: #d40012;
			border-radius: 50%;
			height: 5px;
			top: 50%;
			left: 12px;
			transform: translateY(-50%) scale(1);
			width: 5px;
			opacity: 1;
			transition: transform ease-in 0.1s, opacity ease-in 0.1s; }
		span {
			margin-left: 70px;
			font-size: 60px;
			@include css-lock(52, 80, 960, 2560);
			color: #fff;
			text-transform: uppercase;
			@include ligthItalic;
			transition: opacity 0.3s; }
		.icon {
			display: block;
			font-size: 28px;
			fill: #d40012;
			transition: transform 0.3s, fill 0.3s,opacity 0.3s;
			transform: scale(0); }
		span,
		.icon {
			opacity: 0.35; } }
	&__timeline {
		height: 100%;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(90deg);
		width: 100%;
		svg {
			width: 100%;
			height: 100%; } }
	&.is-scrolled {
		overflow: hidden;
		top: auto;
		bottom: 6rem;
		transform: translateY(0);
		left: 4%;
		width: 45%;
		#{$this}__wrapper {
			display: flex;
			justify-content: center; }
		#{$this}__item {
			&:not(:last-child) {
				margin-bottom: 12px; } }
		#{$this}__link {
			height: 36px;
			width: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 1;
			&:before {
				background-color: #b8bfc3;
				left: 50%;
				margin-left: -2px;
				transition: background-color ease-in-out 0.3s; }
			.icon {
				fill: #fff;
				font-size: 20px;
				transform: scale(1); }
			&:hover {
				&:before {
					background-color: #d40012; }
				.icon {
					fill: #e42c2c; } }
			&.is-active {
				&:before {
					background-color: #d40012; }
				#{$this}__timeline {
					opacity: 1; }
				.icon {
					fill: #e42c2c;
					transform: scale(0.7); } } }
		.icon {
			opacity: 1; }
		span {
			display: none; } } }

.artist-section-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 100%;
	width: 100%;
	transform: translate(-45%, -45%) scale(1.2);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; }

.artist-section {
	$this: &;
	display: flex;
	padding-top: 100px;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&__content {
		height: 100%;
		//padding: 0 10% 2.5rem 5%
		padding: 0 10% 11.5rem 5%;
		display: flex;
		justify-content: space-between;
		width: 100%; }
	&__left,
	&__right {
		height: 100%;
		width: 100%; }
	&__left {
		display: flex;
		flex-direction: column;
		justify-content: flex-end; }
	&__right {
		display: flex;
		.is-content-hidden & {
			opacity: 0; } }
	&__title {
		@include ligthItalic;
		font-size: 55px;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 1.08px;
		line-height: 1;
		color: #ffffff; }
	&_dakha {
		#{$this}__desc {
			max-width: 420px; } }
	&_maneken,
	&_chilly {
		#{$this}__desc {
			max-width: 440px; } } }

.artist-gallery {
	$this: &;
	align-self: center;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 94%;
	&__desc {
		color: #414141;
		@include RobotoLightItalic;
		padding-left: 10%;
		line-height: 1.5;
		min-height: 3.5em;
		@include css-lock(14, 16, 960, 2560); }
	&--chilly,
	&--chilly2,
	&--maneken,
	&--maneken2 {
		#{$this}__desc {
			color: #FFFFFF; } }
	&__items {
		width: 100%;
		padding-top: 70%;
		position: relative;
		margin: auto 0; }
	&__item {
		&:nth-of-type(1) {
			#{$this}__item-overlay {
				background-color: #b8bfc3; } }
		&:nth-of-type(4) {
			#{$this}__item-overlay {
				background-color: #ffffff; } }
		&:nth-of-type(2) {
			#{$this}__item-overlay {
				background-color: #ffffff; } }
		&:nth-of-type(5) {
			#{$this}__item-overlay {
				background-color: #b8bfc3; } }
		&:nth-of-type(3) {
			#{$this}__item-overlay {
				background-color: #26ab94; } }
		&:nth-of-type(6) {
			#{$this}__item-overlay {
				background-color: #ffffff; } } }
	&__item-overlay {
		background-color: #ffffff;
		background-image: url('./../img/noise.png');
		height: 100%;
		position: absolute;
		top: 0;
		transform: translateY(-101%);
		left: 0;
		width: 100%; }
	.is-active-overlay & {
		img, video {
			opacity: 1; } }
	&__item {
		position: absolute;
		overflow: hidden;
		img, video {
			height: 100%;
			width: 100%;
			object-fit: cover;
			opacity: 0; }
		&:nth-child(1) {
			top: 0;
			left: 0;
			width: 50%;
			height: 33%;
			#{$this}__item-overlay {
				background-color: #b8bfc3; } }
		&:nth-child(2) {
			bottom: 0;
			left: 0;
			width: 50%;
			height: 33%;
			#{$this}__item-overlay {
				background-color: #b8bfc3; } }
		&:nth-child(3) {
			right: 0;
			top: 0;
			height: 100%;
			width: 44%;
			#{$this}__item-overlay {
				background-color: #26ab94; } }
		&:nth-child(4) {
			top: 50%;
			left: 20%;
			width: 40%;
			height: 25%;
			#{$this}__item-overlay {
				background-color: #ffffff; } } }

	&--onuka {
		#{$this}__item {
			&:nth-child(1) {
				top: 0;
				left: 12%;
				width: 37.7%;
				height: 23.6%;
				z-index: 1;
				#{$this}__item-overlay {
					background-color: #ffffff; } }
			&:nth-child(2) {
				top: auto;
				left: auto;
				right: 0;
				bottom: 0;
				width: 37.7%;
				height: 23.6%;
				z-index: 1;
				#{$this}__item-overlay {
					background-color: #ffffff; } }
			&:nth-child(3) {
				bottom: auto;
				top: 28%;
				left: 0;
				right: auto;
				height: 56%;
				width: 80%;
				#{$this}__item-overlay {
					background-color: #b8bfc3; } }
			&:nth-child(4) {
				top: 0;
				left: auto;
				right: 0;
				width: 47%;
				height: 31.1%;
				#{$this}__item-overlay {
					background-color: #26ab94; } } } }

	&--onuka2 {
		#{$this}__item {
			&:nth-of-type(4) {
				transform: translateY(-50%); } } }

	&--dahabraha {
		#{$this}__item {
			&:nth-child(1) {
				transform: none;
				top: 0;
				left: 0;
				width: 68.7%;
				height: 52%;
				z-index: 0;
				#{$this}__item-overlay {
					background-color: #b8bfc3; } }
			&:nth-child(2) {
				top: 43.6%;
				left: 15.4%;
				width: 44%;
				height: 30%;
				#{$this}__item-overlay {
					background-color: #ffffff; } }
			&:nth-child(3) {
				bottom: auto;
				top: 0;
				left: auto;
				right: 0;
				height: 61.5%;
				width: 27.8%;
				#{$this}__item-overlay {
					background-color: #26ab94; } }
			&:nth-child(4) {
				top: 65%;
				left: auto;
				right: 0;
				width: 38%;
				height: 24.6%;
				#{$this}__item-overlay {
					background-color: #ffffff; } } } }

	&--dahabraha2 {
		#{$this}__item {
			&:nth-child(1) {
				height: 57.4%;
				top: 29.4%;
				width: 80%; }
			&:nth-child(2) {
				top: 0;
				left: 21.5%;
				height: 32%;
				bottom: auto;
				width: 48%; }
			&:nth-child(3) {
				height: 57.7%;
				width: 27%; }
			&:nth-child(4) {
				bottom: 0;
				right: 0;
				top: auto;
				left: auto; } } }

	&--maneken {
		#{$this}__item {
			&:nth-child(1) {
				top: 0;
				left: 30%;
				width: 39.5%;
				height: 24.8%;
				#{$this}__item-overlay {
					background-color: #ffffff; } }
			&:nth-child(2) {
				bottom: auto;
				top: 0;
				left: auto;
				right: 0;
				height: 58.6%;
				width: 27.2%;
				#{$this}__item-overlay {
					background-color: #26ab94; } }
			&:nth-child(3) {
				top: auto;
				left: 0;
				bottom: 12%;
				width: 80.7%;
				height: 58.6%;
				#{$this}__item-overlay {
					background-color: #b8bfc3; } }
			&:nth-child(4) {
				top: auto;
				bottom: 0;
				left: auto;
				right: 0;
				width: 39.5%;
				height: 24.8%;
				#{$this}__item-overlay {
					background-color: #ffffff; } } } }

	&--maneken2 {
		#{$this}__item {
			&:nth-child(1) {
				height: 24%;
				left: 30.78%;
				width: 39%; }
			&:nth-child(3) {
				bottom: auto;
				top: 0;
				left: auto;
				right: 0;
				height: 58.6%;
				width: 27.2%;
				#{$this}__item-overlay {
					background-color: #26ab94; } }
			&:nth-child(2) {
				top: auto;
				left: 0;
				bottom: 13.8%;
				width: 80%;
				height: 57.7%;
				#{$this}__item-overlay {
					background-color: #b8bfc3; } }
			&:nth-child(4) {
				top: auto;
				left: auto;
				bottom: 0;
				right: 0; } } }

	&--chilly {
		#{$this}__item {
			&:nth-child(1) {
				top: 0;
				left: 18%;
				width: 39.5%;
				height: 24.8%;
				#{$this}__item-overlay {
					background-color: #ffffff; } }
			&:nth-child(2) {
				top: auto;
				left: auto;
				right: 0;
				bottom: 0;
				width: 49.2%;
				height: 32.6%;
				z-index: 1;
				#{$this}__item-overlay {
					background-color: #ffffff; } }
			&:nth-child(3) {
				bottom: auto;
				top: 28%;
				left: 0;
				right: auto;
				height: 58.6%;
				width: 80.7%;
				#{$this}__item-overlay {
					background-color: #b8bfc3; } }
			&:nth-child(4) {
				top: 0;
				left: auto;
				right: 0;
				width: 39.5%;
				height: 24.8%;
				#{$this}__item-overlay {
					background-color: #26ab94; } } } }

	&--chilly2 {
		#{$this}__item {
			&:nth-child(1) {
				top: 0;
				left: 0;
				width: 49.2%;
				height: 32.6%;
				#{$this}__item-overlay {
					background-color: #b8bfc3; } }
			&:nth-child(2) {
				top: 50%;
				transform: translateY(-50%);
				left: 20%;
				width: 40%;
				height: 25%;
				z-index: 1; }
			&:nth-child(3) {
				top: auto;
				bottom: 0;
				left: 0;
				width: 49.2%;
				height: 32.6%; }
			&:nth-child(4) {
				left: auto;
				right: 0;
				top: 0;
				height: 100%;
				width: 45%; } } } }
