.header {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 70%;
	z-index: 10;
	padding: 30px 20px;
	&__inner {
		position: relative;
		display: flex;
		justify-content: center;
		padding: 10px 0; }
	&__logo {
		position: absolute;
		left: 0;
		top: 0;
		transform: translateX(-100%); } }

.logo {
	background: #fff;
	padding: 8px 0px 5px 1px;
	display: block;
	width: 129px;
	height: 33px;
	img {
		height: 100%;
		width: 100%; } }

.nav {
	color: #455255;
	display: flex;
	@include RobotoItalic;
	@include css-lock(10, 22, 960, 2560);
	letter-spacing: 1.07px;
	$this: &;
	&.is-light {
		#{$this}__link {
			color: #fff;
			&.is-active {
				color: rgba(255, 255, 255, 0.26); } } }
	&__item {
		&:not(:last-child) {
			padding-right: 30px;
			position: relative;
			margin-right: 31px;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				height: 4px;
				width: 4px;
				background-color: #ff0000;
				border-radius: 50%; } } }
	&__link {
		&.is-active {
			color: rgba(91, 99, 111, 0.26); } } }
